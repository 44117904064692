%obs-vc-users-form {
 list-style: none;
 padding: 0;
 margin: 0;
 max-height: 28px*15;
 overflow: auto;
 li {
  font-size: 16px;
  line-height: 24px;
  padding: 2px 0;
  label {
   input[type=checkbox] {}
   input[type=checkbox]:checked {}
  }
 }
}
$vc-header-height: 48px;
$vc-summary-height: 100px;
$vc-video-vertical-width: 50vw;
$vc-video-horizontal-height: 50vh;

.ObsVcList {
 padding: $body-margin;
 .title {
  display: flex;
  padding-bottom: 10px;

  h1 {
   @extend %h1-at-air;
   padding-left: 5px;
   flex: 1;
  }
  button {
   border: 0;
   border-radius: 5px;
   background: $color-logo-bg;
   color: #fff;
   font-size: 16px;
   line-height: 30px;
   padding: 0 $body-margin;
   cursor: pointer;
  }
 }
 .no-rooms {
  color: $color-text-light-gray;
 }
 .rooms {
  ul {
   list-style: none;
   padding: 0;
   margin: 0;

   li {

   }
  }
 }
}

.ObsVcListItem {
 .date-row {
  background: $color-background-alternate;
  color: $color-text-light-gray;
  font-size: 16px;
  line-height: 36px;
  padding: 0 $body-margin;
  border-bottom: 1px $color-bg solid;
  margin-top: $body-margin;
 }
 a {
  display: block;
  text-decoration: none;
  color: $color-text-light;
  background: $color-background-body-layout;
  border-bottom: 1px $color-bg solid;
  font-size: 16px;
  transition: all .2s linear;
 }
 a:hover {
  color: $color-header-light;
  background: $color-background-alternate;
 }
 .item-row {
  display: flex;
  align-items: center;
  padding: 10px 0;
  .name {
   display: flex;
   align-items: center;
   font-size: 24px;
   width: 40%;
   box-sizing: border-box;
   padding-left: 15px;
   i {
    font-size: 26px;
    margin-right: 15px;
   }
  }
  .owner {
   box-sizing: border-box;
   width: 35%;
   i {
    margin-right: 10px;
   }
  }
  .dates {
   width: 20%;
   .other_day {
    font-size: 80%;
    margin-left: 1em;
   }
   .other_day:before {
    content: "(";
   }
   .other_day:after {
    content: ")";
   }
  }
  .unread {
   box-sizing: border-box;
   text-align: right;
   width: 10%;
   padding: 0 15px;
   .nums {
    background: #D00;
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    padding: 0 10px;
    border-radius: 4px;
   }
   .numlabel {
    display: none;
   }
  }
 }
 .item-active {
  .name {
   .video-icon {
    color: #D00;
   }
  }
 }
}

.ObsVcCreateRoomForm {
 padding: $body-margin;
 h1 {
  @extend %h1-at-air;
  padding-left: 5px;
 }
 form {
  width: 400px;
  background: $color-header-light;
  color: $color-header-text;

  h3 {
   font-size: 16px;
   line-height: 24px;
   font-weight: normal;
   margin: 0 0 10px;
   padding: 0;
  }
  .name-row {
   padding: 10px $body-margin;
   border-bottom: 1px $color-header-text solid;
   input {
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;
    border: 1px $color-text-light-gray solid;
   }
  }
  .users-row {
   padding: 10px $body-margin;
   border-bottom: 1px $color-header-text solid;
   .title {
    display: flex;
    //align-items: center;
    h3 {
     display: inline-block;
     flex: 1;
    }
    input {
     box-sizing: border-box;
     font-size: 14px;
     line-height: 22px;
     height: 24px;
     padding: 0 4px;
     border-radius: 4px;
     border: 1px $color-text-light-gray solid;
    }
    input::placeholder {
     color: #e7e7e7;
    }
   }
   ul {
    @extend %vc-users-form;
   }
   .no-users {
    color: $color-text-light-gray;
   }
  }
  .actions-row {
   padding: $body-margin;
   button {
    margin-right: $body-margin;
    border: none;
    background: $color-header-text;
    color: $color-text-light-alt;
    font-size: 18px;
    line-height: 28px;
    padding: 0 $body-margin;
    cursor: pointer;
   }
   button:disabled {
    background: $color-text-light-gray;
    color: #e7e7e7;
    cursor: not-allowed;
   }
  }
 }
}

.ObsVcRoom {
 padding: $body-margin;
 .header {
  position: fixed;
  z-index: 120;
  left: $sidebar-width + $body-margin;
  right: $body-margin;
  top: $header-height;
  box-sizing: border-box;
  line-height: $vc-header-height;
  //height: $vc-header-height;
  border-top: $body-margin $color-bg solid;
  border-bottom: 1px $color-bg solid;
  display: flex;
  background: $color-background-alternate;

  h1 {
   margin: 0;
   flex: 1;
   //background: dodgerblue;
   font-weight: normal;
   color: $color-text-light;
   line-height: $vc-header-height;
   font-size: 24px;
   padding: 0 25px;
  }
  .controls {
   position: relative;
   padding-right: 15px;
   button {
    position: relative;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    width: 36px;
    height: 36px;
    box-sizing: border-box;
    padding: 0;
    margin-left: 15px;
    border: none;
    border-radius: 18px;
    background: $color-header-light;
    color: $color-header-text;
    transition: all .1s linear;
    cursor: pointer;
    outline: none;
    label {
     position: absolute;
     z-index: 150;
     bottom: 100%;
     right: 0;
     margin-bottom: 3px;
     max-width: 150px;
     background: #000000dd;
     color: #fff;
     font-size: 14px;
     line-height: 18px;
     padding: 4px 8px;
     border-radius: 4px;
     text-align: left;
     visibility: hidden;
     opacity: 0;
     transition: opacity .1s;
    }
   }
   button:hover {
    background: darken($color-header-light, 10);
    color: darken($color-header-text, 10);
    label {
     visibility: visible;
     opacity: 1;
    }
   }
  }
 }
 .body {
  padding-top: 49px;
 }
 .body-vertical {
  .ObsVcVideo {
   right: 50%;
   bottom: $body-margin;
  }
 }
 .body-horizontal {
  .ObsVcVideo {
   right: $body-margin;
   height: 50%;
  }
 }
}

.ObsVcUsers {
 position: absolute;
 top: 100%;
 right: 0;
 width: 25vw;
 padding: 5px 0 5px 15px;
 background: $color-background-alternate;
 box-shadow: -3px 3px 5px #00000099;
 h3 {
  font-size: 18px;
  margin: 0;
  padding: 0 0 5px;
 }
 ul {
  @extend %vc-users-form;
  li {}
 }
}

.ObsVcVideo {
 background: #00000099;
 position: fixed;
 z-index: 90;
 text-align: center;
 left: $sidebar-width + $body-margin;
 top: $header-height + $body-margin + $vc-header-height;
 display: flex;
 align-items: center;
 h4 {
  width: 100%;
 }
 #vcVideoBox {
  width: 100%;
  height: 100%;
 }
}

.ObsVcClosedRoomBox {
 background: $color-background-alternate;
 box-sizing: border-box;
 position: fixed;
 z-index: 90;
 left: $sidebar-width + $body-margin;
 top: $header-height + $body-margin + $vc-header-height;
 right: $body-margin;
 height: $vc-summary-height;
 padding: 15px 25px;
}

.ObsVcMessages {
 background: $color-background-body-layout;
 ul {
  list-style: none;
  padding: 5px 20px;
  margin: 0;
  li {}
 }
 .no-messages {
  padding: 10px;
  text-align: center;
  color: $color-bg;
  font-style: italic;
 }
}

.ObsVcMessage {
 padding: 5px 0;
 .date {
  text-align: center;
  margin: 10px 0;
  width: 100%;
  line-height: 20px;
  position: relative;

  label {
   font-size: 14px;
   color: #d0cbc8;
   display: inline-block;
   padding: 0 15px;
   z-index: 10;
  }
  label:before, label:after {
   content: "";
   display: block;
   position: absolute;
   top: 10px;
   border-top: 1px #d0cbc8aa solid;
  }
  label:before {
   left: 0;
   right: 50%;
   margin-right: 50px;
  }
  label:after {
   left: 50%;
   right: 0;
   margin-left: 50px;
  }
 }
 .msg {
  display: inline-block;
  border-radius: 5px;
  padding: 10px;
  .user {
   font-size: 90%;
   font-weight: bold;
   color: $color-bg;
   padding-bottom: 5px;
  }
  .time {
   color: #444;
   font-size: 70%;
   margin-left: 20px;
  }
 }
}
.ObsVcMessage.from_me {
 text-align: right;
 .msg {
  border-top-right-radius: 0;
  background: #d0cbc8;
 }
}
.ObsVcMessage.to_me {
 .msg {
  border-top-left-radius: 0;
  background: $color-header-light;
 }
}
.ObsVcMessage.unread {
 .msg {
  background: yellowgreen;
  cursor: pointer;
 }
}


.ObsVcMessageForm {
 position: fixed;
 z-index: 98;
 //left: $sidebar-width + $body-margin;
 right: $body-margin;
 bottom: 0;
 border-bottom: $body-margin $color-bg solid;
 form {
  box-sizing: border-box;
  padding: 15px 80px 15px 20px;
  position: relative;
  min-height: 42px;
  border-top: 1px $color-bg solid;
  background: $color-background-alternate;
 }
 textarea {
  min-height: 18px;
  line-height: 18px;
  width: 100%;
  resize: vertical;
  background: white;
  //box-sizing: border-box;
  padding: 5px 10px;
  border: 0;
 }
 textarea:disabled {
  background: #eee;
  color: #aaa;
  cursor: wait;
 }
 textarea::placeholder {
  color: #ddd;
 }
 button {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  appearance: none;
  background: none;
  border: 0;
  width: 60px;
  min-height: 42px;
  text-align: center;
  vertical-align: center;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  color: $color-header-light;
 }
 button:disabled {
  color: $color-background-body-layout;
  cursor: not-allowed;
 }
}

.ObsVcRoom.closed-room {
 .ObsVcMessages {
  margin-top: $vc-header-height + $body-margin;
  //margin-top: $vc-summary-height + $vc-header-height + $body-margin;
 }
}
.ObsVcRoom.active-room {
 .ObsVcMessages {
  padding-bottom: 100px;
 }
}
.ObsVcRoom.body-vertical {
 padding-left: $vc-video-vertical-width;
 padding-top: $vc-header-height;
 .ObsVcVideo {
  width: $vc-video-vertical-width;
  bottom: $body-margin;
 }
 .ObsVcMessages {
  margin-left: $body-margin*2;
  margin-top: $body-margin*2;
 }
 .ObsVcMessageForm {
  left: $vc-video-vertical-width;
  margin-left: $sidebar-width + $body-margin*2;
 }
}
.ObsVcRoom.body-horizontal {
 padding-top: $vc-video-horizontal-height;
 .ObsVcVideo {
  right: $body-margin;
  height: $vc-video-horizontal-height;
 }
 .ObsVcMessages {
  margin-top: $vc-header-height + $body-margin*2;
 }
 .ObsVcMessageForm {
  left: $sidebar-width + $body-margin;
 }
}

.ObsVcError {
 margin: $body-margin;
 padding: $body-margin;
 background: $color-header-light;
 color: #D00;
 border: 1px #D00 solid;
 label {}
}