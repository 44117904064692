.currency-container {
  white-space: pre-wrap;
  background : #504748;
  padding: 20px;
  border-radius: 6px;
  fontFamily: "Consolas";
  color: white;
  margin-bottom: 5px;
input
{
width: 80px;
  margin: 10px;

}
  
  .rate-data
  {
    background : #857b7a;
    padding: 10px;
    border-radius: 6px;
    fontFamily: "Consolas";
    color: white;
    margin-bottom: 5px;
  }


}