
.MonitorSettingsContainer {
  .Settings{
    height: 36px;
    background: none;
    border: 0;
    outline: none;
    cursor: pointer;
    width: 74px;
  }

  nav {
    height: 50px;
    padding-bottom: 10px;


    }
    ul {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      margin: $body-margin 0 0;
      list-style: none;
      border: 1px $color-bg solid;
      border-radius: 5px;
      box-sizing: border-box;
      overflow: hidden;


    }
    li {
      display: flex;
      border-left: 1px $color-bg solid;
    }
    a {
      line-height: 35px;
      font-size: 16px;
      color: #fff;
    }

  .fas:hover{
    color: $color-logo-bg;
  }
  a.active {
      .fas{
        color: $color-logo-bg;
      }
    }


  }



.MonitorContainer {

  .Settings{
    height: 36px;
    background: none;
    border: 0;
    outline: none;
    cursor: pointer;
  }

  nav {
    height: 50px;
    text-align: center;
    padding-bottom: 10px;

    li:nth-child(3) {
      position: absolute;
      top: 0;
      right: 0;
      a {
        background:none;
      }
      a:hover{
        color: #fff;

      }
      .fas:hover{
        color: $color-logo-bg;
      }

    }
    ul {
      position: relative;
      //padding: 0;
      // margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: $body-margin 0 0;
      list-style: none;
      // display: inline-block;
      border: 1px $color-bg solid;
      border-radius: 5px;
      box-sizing: border-box;
      overflow: hidden;
    }
    li {
      display: flex;
      border-left: 1px $color-bg solid;
    }



    a {
      display: inline-block;
      line-height: 50-$body-margin;
      font-size: 16px;
      background: $color-background-alternate;
      padding: 0 $body-margin;
      text-decoration: none;
      color: #fff;
    }

    a:hover {
      color: $color-text-light-alt;
    }

    a.active {
      background: $color-logo-bg;
    }



  }
}

.Monitor {
  top: 50px;
  .SettingsList{
    padding: 15px;
    table {
      border-collapse: collapse;
      background: $color-background-body-layout;
      width: 100%;
      .fas{
        font-size: 24px;
        margin: 0 5px;
      }
      th {
        text-align: left;
        padding: $body-margin;
        background: $color-background-alternate;
      }
      th.action {
        text-align: right;
      }
      td {
        position: relative;
        padding: $body-margin;
        border-top: 1px $color-bg solid;
        transition: all .1s linear;
        .empty {
          font-style: italic;
          font-size: 80%;
          color: $color-background-alternate;
        }
        a {
          color: #000;
          display: inline-block;
          text-decoration: underline;
          //border-bottom: 1px #000 dashed;
          transition: all .2s linear;
        }
        a:hover {
          color: $color-logo-bg;
          //border-bottom: 1px $color-logo-bg solid;
        }
        a.labeled-link {
          @extend %label-hover-box;
          font-size: 24px;
          label {
            right: 0;
            bottom: 100%;
            margin-bottom: 5px;
          }
        }
        button.labeled-button {
          @extend %label-hover-box;
          font-size: 24px;
          background: none;
          color: #000;
          border: 0;
          outline: none;
          cursor: pointer;
          transition: all .2s linear;
          label {
            right: 0;
            bottom: 100%;
            margin-bottom: 5px;
          }
        }
        button.labeled-button:hover {
          color: $color-logo-bg;
        }
        button.labeled-button[disabled] {
          cursor: not-allowed;
          color: $color-background-alternate;
          label {
            display: none;
          }
        }
      }
      td.actions {
        text-align: left;
        width: 110px;
        a {
          margin: 0 8px;
        }
      }
      tr:hover {
        td {
          background: darken($color-background-body-layout, 2%);
        }
      }
      td.action {
        text-align: left;
        label.date {
          font-size: 80%;
        }
        button {
          @extend %label-hover-box;
          border: 0;
          background: transparent;
          transition: all .1s linear;
          cursor: pointer;
          outline: none;
          label {
            right: 100%;
            top: 50%;
            margin-top: -13px;
            white-space: nowrap;
          }
        }
        button.delete-button {
          color: $color-header-light;
        }
        button.delete-button:hover {
          color: #D00;
        }
      }
      tr.clickable {
        td {
          cursor: pointer;
        }
      }
      tr.clickable:hover {
        td {
          //color: $color-background-alternate;
          color: #fff;
          background: $color-logo-bg;
          .empty {
            color: $color-background-body-layout;
          }
        }
      }
    }
  }

  .title {

    padding-bottom: 10px;

    h1 {
      font-size: 24px;
      line-height: 30px;
      font-weight: normal;
      color: #eeefea;
      padding: 0;
      margin: 0 15px;
    }


  }

  .Filter {
    .css-2b097c-container {
      min-width: 20vw;
    }

    .react-datepicker-wrapper {
      margin-top: 5px;

      input {
        border-radius: 4px;
        border-color: white;
      }
    }

    .Actions {
      height: 36px;
      background: none;
      border: 0;
      outline: none;
      cursor: pointer;

      .fa-sync:hover {
        color: #c3b905;

      }
    }

    h2 {
      font-size: 16px;
      line-height: 30px;
      font-weight: normal;
      color: #eeefea;
      padding: 0;
      margin: 0 15px;
      min-width: 80px;
    }

    h3 {
      font-size: 16px;
      line-height: 30px;
      font-weight: normal;
      color: #eeefea;
      padding: 0;
      margin: 0 15px;
    }

    padding: 10px;
    display: flex;
  }
  .FilterRealTime {
    .css-2b097c-container {
      min-width: 10vw;
    }

    .react-datepicker-wrapper {
      margin-top: 5px;

      input {
        border-radius: 4px;
        border-color: white;
      }
    }

    .Actions {
      height: 36px;
      background: none;
      border: 0;
      outline: none;

      .fa-sync:hover {
        color: #c3b905;

      }
    }

    h2 {
      font-size: 16px;
      line-height: 30px;
      font-weight: normal;
      color: #eeefea;
      padding: 0;
      margin: 0 15px;
      min-width: 80px;
    }

    h3 {
      font-size: 16px;
      line-height: 30px;
      font-weight: normal;
      color: #eeefea;
      padding: 0;
      margin: 0 15px;
    }

    padding: 10px;
    display: flex;
  }
  .RuleFormWrapper{
    height: calc(100vh - 62px);
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    justify-content: center;
  }
  .RuleForm{
    background-color:  $color-background-body-layout;
    margin: 5%;
    min-width: 500px;
    padding: 25px;
    min-height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .Actions{
      display: flex;
      justify-content: flex-end;
      a{
        text-decoration:none;
        color: #504748;
      }
    button {
      margin: 0 10px;
      font-size: 16px;
      line-height: 30px;
      background:#bdbbb9 ;
      color: #504748;
       padding: 0 15px;
      border-radius: 3px;
      border: 0;
      cursor: pointer;
      transition: all .1s linear;
    }
      button:hover{
        background: #c3b905;
      }
    }
    input{
      margin: 0 10px;
      width: 95%;
    }
    h2{
      padding: 5px;
      font-size: 14px;
      line-height: 16px;
      font-weight: normal;
      //color: #eeefea;
    }
    .Container{
      display: flex;
      flex-direction: row;
      .Time{
        margin-top: 5px;
        display:flex;
        div{
          flex:100%;
        }
        input{
          width: 70%;
        }
      }
      input{
        width: 90%;
      }
      select{
        width: 90%;
        margin: 0 10px;
      }
      div{
        flex:100%;
      }
    }

    .title {

      padding-bottom: 10px;

      h1 {
        font-size: 24px;
        line-height: 30px;
        font-weight: normal;
        color: #eeefea;
        padding: 0;
        margin: 0 15px;
      }


    }
  }
}

.NoData {
  text-align: center;
  margin-top: 25vh;
  color: #857b7a;
}

.ReactTable .rt-tr:hover .rt-td {
  background: darken($color-background-body-layout, 5%);

}
.ReactTable{
  margin: 0 15px;
  .TableActions   {
    background: none;
    border: 0;
    outline: none;
    cursor: pointer;
    align-items: center;
    align-content: center;
    color: #504748;


  }
  .TableActions :hover {
    color: #c3b905;

  }
}
.rt-thead {
  position: sticky;
  top: 0;
  //z-index: 2;
  background-color: $color-background-body-layout;


}

.rt-tbody {
  overflow: visible !important;
  height: 200px;
  background-color: $color-background-body-layout;
}

.sticky {
  position: sticky !important;
  left: 0;
  top: 0;
  // z-index: 1;
  background-color: $color-background-body-layout;
}

.Modal {
  background: rgba($color-bg, 0.6);
  height: 72vh;
  width: calc(100% - 76px);
  position: absolute;
  z-index: 100;
  text-align: center;
  .ModalActions   {
    display: inline-flex;
    background: $color-bg;
    border: 1px solid #ccc;
    border-bottom: none;
    visibility: visible;
    width: 80vw;
    justify-content: flex-end;
    button {
      background: none;
      border: 0;
      outline: none;
      cursor: pointer;
      margin-right: 0;
      color: #bdbbb9;
      margin-bottom: 10px;
    }
    .fa-window-close:hover{
      color: #AA0000;
    }

  }
  .Data {

    background: $color-bg;
    border: 1px solid #ccc;
    border-top: none;
    visibility: visible;
    width: 80vw;
    height: 60vh;
    display: inline-block;
    overflow-y: auto;
  video{
      width: 80vw;


      display: inline-block;
      font-size: 16px;
      line-height: 30px;
      font-weight: normal;
      color: #eeefea;
      padding-inline: 20px;

      .Index {
        display: block;
      }
    }
  }
}

