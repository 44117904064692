.weather-container {
  background : #504748;
  padding: 20px;
  border-radius: 6px;
  .weather-data
  {
    background : #857b7a;
 padding: 10px;
    border-radius: 6px;
    fontFamily: "Consolas";
    color: white;
  }
  .weather-data-error
  {
    background : white;
    padding: 10px;
    border-radius: 6px;
    fontFamily: "Consolas";
    color: red;
  }
  .weather-search-box
  {

    padding: 10px;
    margin-left: -10px;
  }

  }



