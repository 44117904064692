
.Chat {
 //@extend %calls-body-layout;
 //position: absolute;
 //bottom: 0;

 .line {
  position: fixed;
  z-index: 1;
  left: $sidebar-width + $contacts-width + 2*$body-margin;
  right: $body-margin;
  top: $header-height;
  bottom: 40 + $body-margin;
  background: $color-background-body-layout;
  box-sizing: border-box;
  line-height: 48px;
 }
 .header {
  position: fixed;
  z-index: 99;
  left: $sidebar-width + $contacts-width + 2*$body-margin;
  right: $body-margin;
  top: $header-height;
  box-sizing: border-box;
  line-height: 48px;
  border-top: $body-margin $color-bg solid;
  h2 {
   margin: 0;
   font-weight: normal;
   color: $color-text-light;
   line-height: 48px;
   font-size: 24px;
   background: $color-background-alternate;
   padding: 0 25px;
   border-bottom: 1px $color-bg solid;
  }
 }
 .no-messages {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: $contacts-width + $sidebar-width + 2*$body-margin;
  top: $header-height + $body-margin;
  right: $body-margin;
  bottom: $body-margin;
  label {
   display: block;
   color: $color-bg;
   font-size: 14px;
   font-style: italic;
   text-align: center;
   width: 100%;
  }
 }
 .messages {
  margin-left: $contacts-width + 2*$body-margin;
  margin-top: $body-margin + 49;
  margin-right: $body-margin;
  padding-bottom: 100px;
  background: $color-background-body-layout;
  box-sizing: border-box;
  position: relative;
  z-index: 90;
  ul {
   list-style: none;
   padding: 5px 20px;
   margin: 0;
   li {
    padding: 5px 0;
    .date {
     text-align: center;
     margin: 10px 0;
     width: 100%;
     line-height: 20px;
     position: relative;

     label {
      font-size: 14px;
      color: #d0cbc8;
      display: inline-block;
      padding: 0 15px;
      z-index: 10;
     }
     label:before, label:after {
      content: "";
      display: block;
      position: absolute;
      top: 10px;
      border-top: 1px #d0cbc8aa solid;
     }
     label:before {
      left: 0;
      right: 50%;
      margin-right: 50px;
     }
     label:after {
      left: 50%;
      right: 0;
      margin-left: 50px;
     }
    }
    .msg {
     display: inline-block;
     border-radius: 5px;
     padding: 10px;
     .time {
      color: #444;
      font-size: 70%;
      margin-left: 20px;
     }
    }
   }
   li.from_me {
    text-align: right;
    .msg {
     border-top-right-radius: 0;
     background: #d0cbc8;
    }
   }
   li.to_me {
    .msg {
     border-top-left-radius: 0;
     background: $color-header-light;
    }
   }
   li.unread {
    .msg {
     background: yellowgreen;
     cursor: pointer;
    }
   }
  }
 }
}

.СhatForm {
 position: fixed;
 z-index: 98;
 left: $sidebar-width + $contacts-width + 2*$body-margin;
 right: $body-margin;
 bottom: 0;
 border-bottom: $body-margin $color-bg solid;
 form {
  box-sizing: border-box;
  padding: 15px 80px 15px 20px;
  position: relative;
  min-height: 42px;
  border-top: 1px $color-bg solid;
  background: $color-background-alternate;
 }
 textarea {
  min-height: 18px;
  line-height: 18px;
  width: 100%;
  resize: vertical;
  background: white;
  //box-sizing: border-box;
  padding: 5px 10px;
  border: 0;
 }
 textarea:disabled {
  background: #eee;
  color: #aaa;
  cursor: wait;
 }
 textarea::placeholder {
  color: #ddd;
 }
 button {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 10px;
  appearance: none;
  background: none;
  border: 0;
  width: 60px;
  min-height: 42px;
  text-align: center;
  vertical-align: center;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  color: $color-header-light;
 }
 button:disabled {
  color: $color-background-body-layout;
  cursor: not-allowed;
 }
}

.ChatUsers {
 position: absolute;
 top: 0;
 right: 0;
 box-shadow: -5px 3px 15px #00000099;
 background: $color-header-light; //$color-background-body-layout;
 color: #111;
 width: 420px;
 padding: 10px;
 visibility: hidden;
 opacity: 0;
 transition: opacity .1s;

 h3 {
  padding: 5px 10px 0;
  margin: 0;
 }

 button.close-button {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  width: 36px;
  height: 36px;
  box-sizing: border-box;
  padding: 0;
  border: none;
  border-radius: 18px;
  background: none;
  color: $color-background-body-layout;
  transition: all .1s linear;
  cursor: pointer;
  outline: none;
  label {
   position: absolute;
   z-index: 150;
   bottom: 100%;
   right: 0;
   margin-bottom: 3px;
   max-width: 150px;
   background: #000000dd;
   color: #fff;
   font-size: 14px;
   line-height: 18px;
   padding: 4px 8px;
   border-radius: 4px;
   text-align: left;
   visibility: hidden;
   opacity: 0;
   transition: opacity .1s;
  }
 }
 button.close-button:hover {
  background: none;
  color: darken($color-background-body-layout, 10);
  label {
   visibility: visible;
   opacity: 1;
  }
 }

 ul {
  list-style: none;
  padding: 0;
  margin: 15px 0 0;
  max-height: 29px*10;
  overflow: auto;
  color: #111;
  border-top: 1px darken($color-header-light, 10) solid;
  li {
   font-size: 16px;
   line-height: 24px;
   padding: 2px 10px;
   border-bottom: 1px darken($color-header-light, 10) solid;
   display: flex;
   align-items: center;
   label {
    flex: 1;
    input[type=checkbox] {
     margin-right: 4px;
    }
    input[type=checkbox]:checked {
    }
   }
   .mentioned {
    cursor: pointer;
    font-style: italic;
   }
   .unmentioned {
    cursor: pointer;
   }
   .itsme {
    font-size: 12px;
    line-height: 18px;
    padding: 0 8px;
    border-radius: 4px;
    margin-left: 16px;
    background: #D00;
    color: #FFF;
   }
  }
 }
}

.ChatUsers.active {
 visibility: visible;
 opacity: 1;
}

