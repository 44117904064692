.Scheduler {
 display: none;
 position: fixed;
 z-index: 199;
 left: 0;
 bottom: 0;
 min-width: 60px;
 max-width: 50%;
 box-sizing: border-box;
 background: #ffffffaa;
 padding: 3px 3px;
 font-size: 12px;
 line-height: 24px;
 span {
  background: #ffff00aa;
  line-height: inherit;
  display: inline-block;
  padding: 0 5px;
  border-radius: 12px;
 }
}

body.dev .Scheduler {
 display: block;
}