.Main {
}

.MainHeader {
 position: fixed;
 z-index: 100;
 left: 0;
 right: 0;
 top: 0;
 height: $header-height;
 background: $color-header-light;
 display: flex;
 justify-content: space-between;

 .LogoText {
  display: flex;
  height: $header-height;
  align-items: center;
  .Logo {
   display: inline-block;
   width: $sidebar-width;
   height: $header-height;
   background: $color-logo-bg;
   box-sizing: border-box;
   padding: 10px;
   .icon {
    display: block;
    width: 100%;
    height: 100%;
    background: url("images/logo.png") center center no-repeat $color-logo-bg;
    background-size: contain;
   }
  }
  .Text {
   display: inline-block;
   color: $color-header-text;
   font-size: 25px;
   text-transform: uppercase;
   margin-left: 19px;
   .version {
    font-size: 14px;
    margin-left: 8px;
   }
  }
 }
 .UserPanel {
  display: flex;
  align-items: center;
  color: #a38e89;
  font-size: 21px;

  .UserPanelControl {
   margin-right: 30px;
   cursor: not-allowed;
  }
  .Search {
   input {
    display: none;
   }
  }
  .Username {
   font-size: 17px;
   margin-right: 10px;
  }
  .LogoutButton {
   appearance: none;
   background: none;
   border: 0;
   width: 50px;
   height: 50px;
   text-align: center;
   font-size: 20px;
   cursor: pointer;
  }
 }
}

.MainSidebar {
 position: fixed;
 z-index: 1001;
 left: 0;
 top: $header-height;
 bottom: 0;
 width: $sidebar-width;
 background: #716b6b;

 nav {
  a {
   display: block;
   position: relative;
   box-sizing: border-box;
   width: 100%;
   height: 53px;
   border: 0;
   border-left: 0;
   border-bottom: 1px solid #979797;
   background-color: transparent;
   background-position: center center;
   background-repeat: no-repeat;
   transition: all 100ms linear;
   text-decoration: none;
   color: $color-text-light-gray;

   label {
    position: absolute;
    z-index: 1001;
    left: 100%;
    top: 50%;
    min-width: 72px;
    margin-top: -16px;
    margin-left: 4px;
    padding: 0 10px;
    line-height: 32px;
    font-size: 16px;
    color: #FFF;
    background: #000000CC;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transition: opacity .1s linear;
   }

   .badge {
    right: 5px;
    top: 3px;
   }

   .active-vc {
    color: #D00;
    animation: vc-active-coloring 2000ms infinite;
   }

  }

  a:hover, a.active, button:hover {
   border-left: 9px solid #c4b905;
   background-color: #4b3939;
  }

  a:hover {
   label {
    visibility: visible;
    opacity: 1;
   }
  }

  a > i {
   display: block;
   line-height: 52px;
   font-size: 20px;
   text-align: center;
  }

  .phone-box {
   position: fixed;
   left: 0;
   bottom: 0;
   padding: $body-margin 0;
   width: $sidebar-width;
   text-align: center;

   .badge {
    right: 5px;
    top: 8px;
   }
  }

  .bot-box {
   position: fixed;
   left: 0;
   bottom: 68px;
   padding: $body-margin 0;
   width: $sidebar-width;
   text-align: center;

   .badge {
    right: 5px;
    top: 8px;
   }
  }


  button.bot-button-active{
   @extend button.bot-button;
   background-color: #4b3939 !important;
   border-color: #4b3939 !important;
  // width: $sidebar-width + $body-margin;
  }
  button.bot-button:hover {

   background-color: #4b3939 !important;
   border-color: #4b3939 !important;
  }

  button.phone-button, button.bot-button {
   display: inline-block;
   position: relative;
   z-index: 1000;
   box-sizing: border-box;
   width: 58px;
   height: 58px;
   border-radius: 58px;
   border: 3px solid $color-background-alternate;
   //background-color: transparent;
   background: $color-bg;
   transition: all 100ms linear;
   text-decoration: none;
   color: $color-text-light-gray;
   cursor: pointer;
   outline: none;
   line-height: 52px;
   font-size: 22px;
   text-align: center;
  }
  button.phone-button:hover {
   border-color: $color-bg;
  }
  button.phone-button-offline { // оффлайн
   display: none;
   //color: $color-background-body-layout;
  }
  button.phone-button-connecting > i { // соединение
   animation: phone-connecting 1000ms infinite;
  }
  button.phone-button-idle { // режим ожидания

  }
  button.phone-button-incoming > i { // входящий звонок
   animation: phone-incoming 3000ms infinite;
   font-size: 28px;
  }
  button.phone-button-outgoing > i { // исходящий звонок
   animation: phone-outgoing 2000ms infinite, phone-rotating 3000ms infinite;
  }
  button.phone-button-active { // в процессе разговора
   animation: phone-active 2000ms infinite, phone-rotating 3000ms infinite;
   font-size: 28px;
  }
  .badge {
   font-size: 14px;
   color: yellowgreen;
   position: absolute;
  }
 }
}

body.phone-active .phone-button {
 //background-color: #000 !important;
 background-color: #4b3939 !important;
 border-color: #4b3939 !important;
 width: $sidebar-width + $body-margin;
}

@keyframes phone-connecting {
 0% { color: $color-background-body-layout; }
 40% { color: $color-background-alternate ; }
 80% { color: $color-background-body-layout; }
 100% { color: $color-background-body-layout; }
}
@keyframes phone-rotating {
 0% { transform: rotate(0); }
 20% { transform: rotate(-45deg); }
 40% { transform: rotate(0deg); }
 100% { transform: rotate(0deg); }
}
@keyframes phone-active {
 0% {color: #d00;}
 50% {color: #f00;}
 100% {color: #d00;}
}
@keyframes phone-outgoing {
 0% {color: $color-text-light;}
 50% {color: $color-text-light-gray;}
 100% {color: $color-text-light-alt;}
}
@keyframes phone-incoming {
 0% { transform: translate3d(0em, 0, 0); }
 2% { transform: translate3d(0.1em, 0, 0); }
 4% { transform: translate3d(-0.1em, 0, 0); }
 6% { transform: translate3d(0.1em, 0, 0); }
 8% { transform: translate3d(-0.1em, 0, 0); }
 10% { transform: translate3d(0.1em, 0, 0); }
 12% { transform: translate3d(-0.1em, 0, 0); }
 14% { transform: translate3d(0.1em, 0, 0); }
 16% { transform: translate3d(-0.1em, 0, 0); }
 18% { transform: translate3d(0.1em, 0, 0); }
 20% { transform: translate3d(-0.1em, 0, 0); }
 22% { transform: translate3d(0.1em, 0, 0); }
 24% { transform: translate3d(-0.1em, 0, 0); }
 26% { transform: translate3d(0.1em, 0, 0); }
 28% { transform: translate3d(-0.1em, 0, 0); }
 30% { transform: translate3d(0.1em, 0, 0); }
 32% { transform: translate3d(-0.1em, 0, 0); }
 34% { transform: translate3d(0.1em, 0, 0); }
 36% { transform: translate3d(-0.1em, 0, 0); }
 38% { transform: translate3d(0.1em, 0, 0); }
 40% { transform: translate3d(-0.1em, 0, 0); }
 42% { transform: translate3d(0.1em, 0, 0); }
 44% { transform: translate3d(-0.1em, 0, 0); }
 46% { transform: translate3d(0em, 0, 0); }
}
@keyframes vc-active-coloring {
 0% {color: #d00;}
 50% {color: #fff;}
 100% {color: #d00;}
}

.MainBody {
 box-sizing: border-box;
 padding-top: $header-height;
 padding-left: $sidebar-width;
 min-height: 100vh;
 position: relative;
}

.Live {
 position: fixed;
 z-index: 10000;
 right: 1px;
 top: 1px;
 width: 10px;
 height: 10px;
 border-radius: 10px;
 background: gray;
 label {
  display: none;
 }
}

.Live.online {
 background: green;
}