.Login {
 background: url('images/oil.jpg') no-repeat center center;
 background-size: cover;
 display: flex;
 align-items: center;
 height: 100%;
 min-height: 350px;

 .SignInBlock {
  margin: 0 auto;
  width: 352px;
  .Logo {
   background: url('images/logo.png') center top no-repeat;
   padding-top: 80px;
   text-align: center;
   h1 {
    text-transform: uppercase;
    color: #f8fdff;
    font-size: 30px;
    font-weight: normal;
   }
  }
  .SignInForm {
   margin-top: 40px;

   input {
    box-sizing: border-box;
    border: none;
    outline: none;
    width: 100%;
    padding: 16px 22px;
    margin-bottom: 13px;
    font-size: 16px;
    background: rgba(255, 255, 255, 0.35);
    color: #eeffff;
   }
   input::placeholder {
    color: #ddd;
   }
   input:disabled {
    opacity: .3;
   }
   button {
    height: 50px;
    width: 100%;
    margin-top: 23px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    background: #c4b905;
    color: #eeffff;
    cursor: pointer;
    outline: none;
   }
   button:hover {
    background: #dace0b;
    transition: background-color 100ms linear;
   }
   button:disabled {
    background: #ccc;
    cursor: not-allowed;
    opacity: .3;
   }
  }
 }
}