.CallsHistory {
 margin: 15px;
 background: $color-background-body-layout;

 .Messages {
  background: #857b7a;
  width: 100%;
 }

 .Calls-Day {
  padding-bottom: 30px;
 }

 .CallsDay-Title {
  background: #706262;
  color: #f3e5dc;
  font-size: 18px;
  padding: 9px;
  padding-left: 35px;
  margin-bottom: 20px;
 }

 .CallsDay-List {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
 }

 .CallsDay-List td {
  /* border-bottom: 1px solid #463d3e; */
  /*padding: 8px 0;*/
 }

 td.CallDayListItem-Avatar {
  padding-left: 7px;
  padding-right: 7px;
  width: 70px;
  /* border-bottom: 1px solid transparent; */
 }

 .CallDayListItem-Person {
  /* color: #eeefea; */
  font-size: 15px;
  width: 320px;
 }

 .CallDayListItemPerson-Name,
 .CallDayListItemTime-HM {
  color: #eeefea;
 }

 .CallDayListItemPerson-Profession,
 .CallDayListItemTime-S {
  color: #d0cbc8;
  font-size: 13px;
 }

 .CallDayListItem-Type {
  width: 120px;
 }

 .CallDayListItem-Actions {
  max-width: 60%;
  text-align: right;
  font-size: 25px;
  color: #bcb4b2;
 }

 .CallDayListItem-Actions i {
  margin-right: 30px;
  cursor: pointer;
 }

 .CallDayListItem-Actions i:hover {
  -webkit-transition: color 100ms linear;
  -ms-transition: color 100ms linear;
  transition: color 100ms linear;
 }

 .CallDayListItem-Actions i.fa-phone:hover {
  color: #d30227;
 }

 .CallDayListItem-Actions i.fa-video:hover {
  color: #0dbeea;
 }

 .CallDayListItemChecked {
  background-color: #766a6a;
  border-bottom: 3px solid #706262;
 }

 .CallDayListItemChosen {
  border-left: 3px solid #c4b905;
  border-bottom: 3px solid #c4b905;
  border-top: 3px solid #c4b905;
  border-right: 3px solid #c4b905;
 }

}
