.react-chatbot-kit-chat-container {
//  width: 340px;
}
.react-chatbot-kit-chat-input-container{

  input::placeholder {
    //font-size: 0;
  }
  .react-chatbot-kit-chat-btn-send{
    background-color: #766a6a;
  }


}
.bot-options{
  margin: 0 10px;
 background: #f6f0e4;
  padding: 10px;
  border-radius: 12px;


span{
  margin: 0 10px;
}
  button{
    border: 0;
    margin-bottom: 10px;
    margin-right: 10px ;

   line-height:32px;
  border-left-width: 10px;
   height: 32px;
    border-radius: 12px;
    box-sizing: border-box;
    display: inline-block;
    color: #FFF;
    outline: none;
    transition: all .1s linear;
    cursor: pointer;
    background: #857b7a;
  }
  button:hover {
    color:  #FFF;
    background: #766a6a;
  }
}
