.leaflet-container {
 height: 100%;
 width: 100%;
}

.MapContainer {
 nav {
  height: 50px;
  text-align: center;

  ul {
   padding: 0;
   margin: $body-margin 0 0;
   list-style: none;
   display: inline-block;
   border: 1px $color-bg solid;
   border-radius: 5px;
   box-sizing: border-box;
   overflow: hidden;
  }
  li {
   display: inline-block;
   border-left: 1px $color-bg solid;
  }
  ul > li:first-child {
   border-left: 0;
  }
  a {
   display: inline-block;
   line-height: 50-$body-margin;
   font-size: 16px;
   background: $color-background-alternate;
   padding: 0 $body-margin;
   text-decoration: none;
   color: #fff;
  }
  a:hover {
   color: $color-text-light-alt;
  }
  a.active {
   background: $color-logo-bg;
  }
 }
}

.MapWrapper {
 position: fixed;
 top: $header-height + $body-margin + 50;
 left: $sidebar-width + $body-margin;
 right: $body-margin;
 bottom: $body-margin;
 .map-row {
  width: 100%;
  height: 100%;
 }

 .loader {
  position: absolute;
  z-index: 10000;
  background: #504748aa;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  i {
   position: absolute;
   display: inline-block;
   width: 72px;
   height: 72px;
   font-size: 72px;
   left: 50%;
   top: 50%;
   margin-top: -36px;
   margin-left: -36px;
   color: $color-text-light;
   animation: spin 3s linear infinite;
  }
  @keyframes spin {
   100% {
    transform: rotate(360deg);
   }
  }
 }
}

.MapJournalWrapper {
 position: fixed;
 top: $header-height + $body-margin + 50;
 left: $sidebar-width + $body-margin;
 right: $body-margin;
 bottom: $body-margin;
 display: flex;
 flex-direction: column;
 .map-row {
  flex: 1;
  transition: all .15s linear;
 }
 .journal-row {
  position: relative;
  margin-top: $body-margin;
  height: 40px;
  background: $color-background-body-layout;
  transition: all .15s linear;
  button {
   width: 100%;
   border: 0;
   background: $color-background-body-layout;
   color: $color-text-light-alt;
   text-align: left;
   padding: 0 20px;
   line-height: 40px;
   font-size: 16px;
   transition: all 100ms linear;
   outline: none;
   cursor: pointer;
  }
  button:hover {
   background: $color-background-alternate;
  }
  .journal {
   position: absolute;
   top: 40px;
   bottom: 0;
   left: 0;
   width: 100%;
   overflow: hidden;
   background: $color-header-light;
   ul {
    padding: 10px 20px;
    margin: 0;
    list-style: none;
    li {
     color: $color-background-alternate;
     padding: 5px 0;
     span {
     }
     .time {
      margin-right: 8px;
     }
     .who {
      margin-right: 8px;
     }
     .state {
     }
     a {
      color: $color-bg;
      text-decoration: none;
     }
    }
    li.danger {
     color: #d00;
    }
   }
  }
 }
}

.MapJournalWrapper.journal-active {
 .map-row {
 }
 .journal-row {
  height: 33%;
  .journal {
   overflow: auto;
  }
 }
}

.MapSidebarBox {
 height: 100%;
 display: flex;
 .Map {
  flex: 1;
  height: 100%;
 }
 .Sidebar {
  background: $color-background-body-layout;
  width: 33%;
  height: 300px;
  min-height: 100%;
  margin-left: $body-margin;
  position: relative;

  h3 {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   height: 49px;
   line-height: 49px;
   font-size: 20px;
   margin: 0;
   padding: 0 10px 0 20px;
   border-bottom: 1px $color-bg solid;
   background: $color-background-alternate;
   color: $color-text-light;
   button {
    appearance: none;
    background: none;
    border: 0;
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 24px;
    line-height: 39px;
    color: $color-text-light;
    cursor: pointer;
    transition: all .1s linear;
   }
   button:hover {
    color: $color-logo-bg;
   }
  }
  .history-date {
   height: 50px;
   box-sizing: border-box;
   border-bottom: 1px $color-bg solid;
   background: $color-background-alternate;
   padding: 0 15px;
   label {
    display: inline-block;
    position: relative;
    color: $color-text-light;
    line-height: 49px;
    margin-right: 8px;
    font-size: 18px;
    padding-left: 59px;
    i {
     display: inline-block;
     width: 31px;
     height: 31px;
     position: absolute;
     left: 10px;
     top: 50%;
     margin-top: -16px;
     font-size: 31px;
     color: $color-background-body-layout;
    }
   }
   label:after {
    content: ":"
   }
   input {
    appearance: none;
    display: inline-block;
    width: 100px;
    color: $color-text-light;
    line-height: 49px;
    border: 0;
    padding: 0;
    background: none;
    font-size: 18px;
    outline: none;
   }
  }
  .history-select-user {
   height: 50px;
   box-sizing: border-box;
   border-bottom: 1px $color-bg solid;
   background: $color-logo-bg;
   padding: 0 15px;
   label {
    display: inline-block;
    position: relative;
    color: $color-text-light;
    line-height: 49px;
    margin-right: 8px;
    font-size: 18px;
    padding-left: 59px;
    i {
     display: inline-block;
     width: 31px;
     height: 31px;
     position: absolute;
     left: 10px;
     top: 50%;
     margin-top: -16px;
     font-size: 31px;
    }
   }
  }
  label.select-user {
   display: block;
   position: relative;
   padding: 15px 15px 10px;
   line-height: 24px;
   background: $color-background-alternate;
   color: $color-logo-bg;
   i {
    display: none;
    font-size: 24px;
    margin-right: 20px;
   }
  }
  .history-users {
   position: absolute;
   top: 50px;
   left: 0;
   right: 0;
   bottom: 0;
   overflow: auto;
  }
  .realtime-users {
   position: absolute;
   top: 50px;
   left: 0;
   right: 0;
   bottom: 0;
   overflow: auto;
  }
  ul.persons {
   list-style: none;
   margin: 0;
   padding: 0;
   li {
    position: relative;
    button, a {
     appearance: none;
     display: flex;
     width: 100%;
     align-items: center;
     box-sizing: border-box;
     border-top: 0;
     border-right: 0;
     border-bottom: 1px solid #979797;
     border-left: 9px transparent solid;
     background: url("images/user-icon.png") 6px 50% no-repeat;
     min-height: 71px;
     padding: 5px 0 5px 60px;
     color: $color-text-light-alt;
     text-decoration: none;
     text-align: left;
     font-size: 15px;
     transition: all 100ms linear;
     cursor: pointer;
     div.person {
      display: block;
      color: #d0cbc8;
     }
     .name {
      display: inline-block;
      padding: 4px;
     }
     .email {
      font-size: 13px;
     }
     .status {
      font-size: 13px;
      line-height: 21px;
      display: inline-block;
      padding: 0 4px;
      border-radius: 3px;
     }
     div.danger .status {
      background-color: #D00;
      color: #FFF;
     }
    }
    button:hover, a:hover, a.active {
     border-left: 9px solid #c4b905;
     background-color: #766a6a;
    }

   }
  }
  .Card {
   position: absolute;
   top: 50px;
   left: 0;
   right: 0;
   bottom: 50px;
   overflow: auto;
   text-align: center;
   color: $color-bg;
   h2 {
    background: url("./images/user-icon.png") center top no-repeat;
    font-size: 24px;
    padding: 64px 0 0;
    margin: 15px 0;
   }
   .links {
    margin: 25px 0 15px;
    a, button {
     display: inline-block;
     position: relative;
     font-size: 36px;
     line-height: 42px;
     width: 42px;
     text-align: center;
     text-decoration: none;
     margin: 0 15px;
     color: $color-text-light;
     outline: none;
     border: 0;
     background: transparent;
     cursor: pointer;
     span {
      position: absolute;
      z-index: 10;
      bottom: 100%;
      left: 0;
      max-width: 120px;
      background: #000000dd;
      color: #fff;
      font-size: 14px;
      line-height: 18px;
      padding: 4px 8px;
      border-radius: 4px;
      text-align: left;
      visibility: hidden;
      opacity: 0;
      transition: opacity .1s;
     }
    }
    a:hover, button:hover {
     color: $color-logo-bg;
     span {
      visibility: visible;
      opacity: 1;
     }
    }
    button:disabled {
     color: $color-background-alternate;
     cursor: not-allowed;
     span {
      display: none;
     }
    }
   }
   .error {
    margin: 15px 0;
    color: $color-background-alternate;
   }
  }
  .close {
   position: absolute;
   left: 0;
   right: 0;
   bottom: 0;
   height: 49px;
   border-top: 1px $color-bg solid;
   background: $color-background-alternate;
   button, a {
    display: block;
    width: 100%;
    text-align: center;
    background: none;
    color: $color-header-light;
    font-size: 18px;
    line-height: 49px;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    transition: all 100ms linear;
   }
   button:hover, a:hover {
    background: $color-logo-bg;
   }
  }
 }
}

.HistoryMapBox {
 box-sizing: border-box;
 position: relative;
 padding-top: 50px;
}

.HistoryMap {
 width: 100%;
 height: 100%;
 box-sizing: border-box;
}

.HistoryTimeline {
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 height: 50px;
 box-sizing: border-box;
 background: #ddd;
 color: #333;
 border-bottom: 1px $color-bg solid;

 .timeline-box {
  position: relative;
  padding-left: 100px;
  height: 100%;
  select {
   box-sizing: border-box;
   width: 100px;
   height: 100%;
   position: absolute;
   left: 0;
   top: 0;
  }
 }
 ul {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: auto;
  li {
   height: 100%;
   button {
    padding: 0 10px;
    font-size: 16px;
    cursor: pointer;
    background: transparent;
    border: none;
    height: 100%;
   }
  }
  li.selected {
   button {
    color: $color-bg;
    background: white;
   }
  }
 }
}