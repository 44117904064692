$contacts-width: 280px;

%calls-body-layout {
 margin-right: $body-margin;
 margin-left: $contacts-width + 2*$body-margin;
 margin-top: $body-margin;
 min-height: 100%;

 //position: fixed;
 //z-index: 10;
 //left: $contacts-width + $sidebar-width + 2*$body-margin;
 //top: $header-height + $body-margin;
 //right: $body-margin;
 //bottom: 0;

 background: $color-background-body-layout;
 box-sizing: border-box;
}

.Messages {
 min-height: 100%;
 .Empty {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: $contacts-width + $sidebar-width + 2*$body-margin;
  top: $header-height + $body-margin;
  right: $body-margin;
  bottom: $body-margin;
  background: $color-background-body-layout;

  .hello {
   color: $color-bg;
   font-size: 18px;
   font-style: italic;
   text-align: center;
   width: 100%;
  }
  .loading {
   color: $color-bg;
   font-size: 14px;
   font-style: italic;
   text-align: center;
   width: 100%;
  }
 }
}
.Contacts {
 h3 {
  position: fixed;
  left: $sidebar-width + $body-margin;
  top: $header-height + $body-margin;
  width: $contacts-width;
  margin: 0;
  box-sizing: border-box;
  background: $color-background-alternate;
  border-bottom: 1px $color-bg solid;
  color: $color-text-light;
  font-weight: normal;
  font-size: 20px;
  line-height: 48px;
  padding: 0 10px 0 20px;
 }
 ul.persons {
  position: fixed;
  left: $sidebar-width + $body-margin;
  top: ($header-height + $body-margin + 49);
  width: $contacts-width;
  background: $color-background-body-layout;
  bottom: $body-margin;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  li {
   position: relative;
   .badge {
    color: yellowgreen;
    position: absolute;
    right: 5px;
    top: 5px;
   }
   a {
    transition: all 100ms linear;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #979797;
    border-left: 9px transparent solid;
    background: url("images/user-icon.png") 6px 50% no-repeat;
    min-height: 71px;
    padding: 5px 0 5px 64px;
    color: $color-text-light-alt;
    text-decoration: none;
    font-size: 15px;
    div.person {
     display: block;
    }
    .name {
    }
    .email {
     font-size: 13px;
     color: #d0cbc8;
    }
   }
   a:hover, a.active {
    border-left: 9px solid #c4b905;
    background-color: #766a6a;
   }
  }
 }
 .fetching {
  color: $color-bg;
  font-size: 12px;
  font-style: italic;
  padding: 10px 0; // 10px 73px;
  text-align: center;
 }
}
