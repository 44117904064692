$phone-width: 360px;
$phone-box-padding: 25px;
$phone-answer-color: green;
$phone-keyboard-color: gray;
$phone-reject-color: red;
$phone-disable-color: $color-background-alternate;

%phone-box {
 width: $phone-width;
 //background: #000000dd;
 background: #4b3939f6;
 text-align: center;
 padding: $phone-box-padding;
 box-sizing: border-box;
 position: relative;
 //border: 1px #000 solid;
}
%bot-box {
 width: $phone-width;
 //background: #000000dd;
 background: #4b3939f6;
 text-align: center;
 padding: 30px;
 box-sizing: border-box;
 position: relative;
 //border: 1px #000 solid;
}

%phone-button-labeled {
 position: relative;
 label {
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  max-width: 120px;
  margin-top: 10px;
  background: #000000dd;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  padding: 4px 8px;
  border-radius: 4px;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  transition: opacity .1s;
 }
}

%phone-button-labeled:hover {
 label {
  visibility: visible;
  opacity: 1;
 }
}

%phone-button {
 font-size: 32px;
 border: 0;
 padding: 0;
 margin: 0 10px;
 line-height: 64px;
 width: 64px;
 height: 64px;
 border-radius: 32px;
 box-sizing: border-box;
 display: inline-block;
 color: #FFF;
 outline: none;
 transition: all .1s linear;
 cursor: pointer;
}

body.phone-active .Phone {
 display: block;
}

.Phone {
 display: none;
 position: fixed;
 left: $sidebar-width + $body-margin/2;
 top: $header-height + $body-margin/2;
 z-index: 1000;
 box-shadow: 0 0 15px #000000cc;

 .hide-button {
  //@extend %phone-button-labeled;
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 0;
  padding: 0 4px;
  border: 0;
  font-size: 24px;
  line-height: 32px;
  //width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  background: transparent;
  color: $color-text-light-gray;
  transition: all .1s linear;
  cursor: pointer !important;
  i {
   transform: rotate(-45deg);
   transition: inherit;
  }
  label {
   display: inline-block;
   font-size: 16px;
   margin: 0 8px;
   visibility: hidden;
   transition: inherit;
   cursor: pointer;
  }
 }
 .hide-button:hover {
  background: #000000dd;
  //background: $color-background-body-layout;
  i {
   color: $color-text-light-alt;
  }
  label {
   color: $color-text-light-alt;
   visibility: visible;
  }
 }

 .CallForm {
  input {
   width: 100%;
   font-size: 36px;
   text-align: center;
   box-sizing: border-box;
   border: 0;
   border-radius: 2px;
   padding: 3px 0;
  }
  input::placeholder {
   color: #ccc;
   font-weight: 200;
   //font-size: 24px;
  }
  .actions {
   padding-top: 20px;
   button[type=submit] {
    @extend %phone-button;
    @extend %phone-button-labeled;
    background: $phone-answer-color;
   }
   button:disabled {
    background: $color-background-alternate;
    color: $color-background-body-layout;
    cursor: not-allowed;
   }
  }
 }
 .ContactsTab {
  .search-box {
   background: #fff;
   display: flex;
   flex-direction: row-reverse;
   align-items: center;
   padding: 0 8px;
   i {
    font-size: 22px;
    margin-right: 8px;
    color: $color-background-body-layout;
   }
   input {
    width: 100%;
    background: #fff;
    border: 1px $color-background-body-layout;
    font-size: 16px;
    line-height: 36px;
    color: #333;
    outline: none;
   }
   input::placeholder {
    color: #ccc;
   }
   input:focus {
   }
   input:focus + i {
    color: #333;
   }
  }
  .contacts {
   padding-bottom: 10px;
   min-height: 160px;
   ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    max-height: 330px;
    overflow: auto;
    //background: #4b3939f7;
    //background-color: $color-background-body-layout;

    li {
     font-size: 18px;
     margin: 0;
     padding: 0;
     border-top: 1px $color-bg solid;
     button {
      width: 100%;
      background: none;
      border: 0;
      color: $color-text-light;
      text-align: left;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 36px;
      justify-content: space-between;
      padding: 5px 8px;
      cursor: pointer;
      outline: none;
      transition: all .1s linear;
      .name {
       display: flex;
       align-items: center;
       i {
        font-size: 22px;
        margin-right: 8px;
       }
      }
      .number {
       font-size: 20px;
      }
     }
     button:hover {
      color: $color-text-light-alt;
      background: #4b3939ed;
     }
    }
   }
   .no-contact {
    font-size: 18px;
    line-height: 96px;
    color: $color-background-alternate;
   }
  }
 }
 .HistoryTab {
  padding-bottom: 10px;
  min-height: 160px;
  ul {
   list-style: none;
   padding: 0;
   margin: 0;
   max-height: 377px;
   overflow: auto;
   li {
    font-size: 18px;
    margin: 0;
    padding: 0;
    border-top: 1px $color-bg solid;
    .date {
     text-align: center;
     margin: 10px 0;
     width: 100%;
     line-height: 20px;
     position: relative;

     span {
      font-size: 14px;
      color: #d0cbc8;
      display: inline-block;
      padding: 0 15px;
      z-index: 10;
     }
     span:before, span:after {
      content: "";
      display: block;
      position: absolute;
      top: 10px;
      border-top: 1px #d0cbc8aa solid;
     }
     span:before {
      left: 0;
      right: 50%;
      margin-right: 50px;
     }
     span:after {
      left: 50%;
      right: 0;
      margin-left: 50px;
     }
    }
    button {
     width: 100%;
     background: none;
     border: 0;
     color: $color-text-light;
     text-align: left;
     cursor: pointer;
     display: flex;
     align-items: center;
     font-size: 18px;
     line-height: 36px;
     justify-content: space-between;
     padding: 5px 8px;
     cursor: pointer;
     outline: none;
     transition: all .1s linear;
     .who {
      display: flex;
      align-items: center;
      .history-icon {
       display: inline-block;
       width: 20px;
       height: 20px;
       margin-right: 8px;
       i {
        display: block;
        width: 20px;
        height: 20px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: 0 0;
       }
       label {
        display: none;
       }
      }
      .name {}
      .number {
       font-style: italic;
       font-size: 22px;
      }
     }
     .when {
     }
    }
    button:hover {
     background: #4b3939ed;
    }
    .new-missed-call {
     color: $color-logo-bg;
    }
   }
   li:first-child {
    border-top: 0;
   }
  }
  .no-history {
   font-size: 18px;
   line-height: 96px;
   color: $color-background-alternate;
  }
 }

 .DialBox {
  @extend %phone-box;
  padding: 0;
  .tabs {
   ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
     display: inline-block;
     box-sizing: border-box;
     width: 33.33%;
     border-left: 1px $color-bg solid;
     button {
      width: 100%;
      outline: none;
      border: none;
      position: relative;
      text-align: center;
      background-color: $color-background-body-layout;
      background-position: 50% 4px;
      background-repeat: no-repeat;
      padding: 28px 10px 6px;
      font-size: 14px;
      color: $color-text-light;
      cursor: pointer;
      transition: all .1s linear;
     }
     button:hover {
      background-color: $color-background-alternate;
     }
    }
    li:first-child {
     border-left: 0;
    }

    li.active {
     button {
      background-color: transparent;
     }
    }
    button.dialpad {
     background-image: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23e7eee6' d='M24 38c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zM12 2C9.79 2 8 3.79 8 6s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 12c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 12c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm24-16c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zM24 26c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm12 0c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0-12c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm-12 0c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0-12c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z'/%3E%3C/svg%3E");
    }
    button.contacts {
     background-image: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23e7eee6'%3E%3Cpath d='M19,3H8C6.346,3,5,4.346,5,6v1H4C3.447,7,3,7.448,3,8s0.447,1,1,1h1v2H4c-0.553,0-1,0.448-1,1s0.447,1,1,1h1v2H4 c-0.553,0-1,0.448-1,1s0.447,1,1,1h1v1c0,1.654,1.346,3,3,3h11c1.654,0,3-1.346,3-3V6C22,4.346,20.654,3,19,3z M7,6 c0-0.551,0.449-1,1-1v2H7V6z M7,9h1v2H7V9z M7,13h1v2H7V13z M7,18v-1h1v2C7.449,19,7,18.551,7,18z M20,18c0,0.551-0.449,1-1,1H9V5 h10c0.551,0,1,0.449,1,1V18z'/%3E%3Ccircle cx='14' cy='10.5' r='2'/%3E%3Cpath d='M14,13.356c-1.562,0-2.5,0.715-2.5,1.429c0,0.357,0.938,0.715,2.5,0.715c1.466,0,2.5-0.357,2.5-0.715 C16.5,14.071,15.52,13.356,14,13.356z'/%3E%3C/g%3E%3C/svg%3E");
    }
    button.history {
     background-image: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='-2 -2 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23e7eee6' d='M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 L10,0 Z M10,18 C5.6,18 2,14.4 2,10 C2,5.6 5.6,2 10,2 C14.4,2 18,5.6 18,10 C18,14.4 14.4,18 10,18 Z M10.5,5 L9,5 L9,11 L14.2,14.2 L15,12.9 L10.5,10.2 L10.5,5 Z'/%3E%3C/svg%3E");
     position: relative;
     .counter {
      position: absolute;
      top: 3px;
      left: 50%;
      margin-left: -10px;
      //right: 10px;
      display: inline-block;
      width: 24px;
      line-height: 24px;
      border-radius: 12px;
      font-size: 10px;
      font-weight: bold;
      background: $color-logo-bg;
      //text-shadow: 0 0 1px #000000CC;
      //background: yellowgreen;
      color: $color-bg;
     }
    }
   }
  }
  .body {
   padding: $phone-box-padding;
  }
 }
 .CallBox {
  @extend %phone-box;
  .title {
   color: $color-text-light-alt;
   font-size: 14px;
  }
  .name {
   color: $color-text-light;
   font-size: 36px;
   padding: 20px 0;
  }
  .contact {
   color: $color-text-light;
   font-size: 14px;
   padding-bottom: 20px;
  }
  .actions {
   padding-top: 20px;

   button {
    @extend %phone-button;
    @extend %phone-button-labeled;
   }
   button.answer {
    background: $phone-answer-color;
   }
   button.reject {
    background: $phone-reject-color;
    margin: 0 10px;
   }
   button.reject > i {
    transform: rotate(225deg);
   }
  }
 }
 .PhoneKeyboard {
  position: relative;
  $phone-input-width:100%;
  .input{
   input[type="text"] {

    width: $phone-input-width;
    padding: 15px;
    height: 51px;
    font-size: 24px;
    text-align: center;
    box-sizing: border-box;
    border: 0;
    border-radius: 2px;


   }
   button {
    background-color: transparent;
    position: absolute;
    right: 1px;
    top: 12px;
    padding: 15px;
    width: 40px;
    height: 30px;
    border: 0px;
    border-radius: 0px;
    line-height: 30px;
    font-size: 24px;
    text-align: center;
    align-items: center;
    margin: 0 ;
    vertical-align: center;
    outline: none;
    transition: all .1s linear;
   }
   i {
    position: absolute;
    right: 5px;
    top: 3px;
    color: darkgray;

   }
   button:disabled {
    i {
     color: lightgrey;
    }
   }
   button:hover {
    i {
     color: $phone-keyboard-color;
    }
   // background: #ccc;
   }
   button[disabled]:hover {
    background-color: Transparent;
    i {
     color: lightgrey;
    }
   }

   input::placeholder {
    color: #ccc;
    font-weight: 200;
    text-align: center;
   }

  }



  .actions {
   padding-top: 20px;

   button {

    background: $phone-keyboard-color;
    width: 64px;
    height: 48px;
    border: 0;
    border-radius: 10px;
    line-height: 48px;
    font-size: 24px;
    text-align: center;
    align-items: center;
    margin: 0 10px;
    vertical-align: top;
    color: #FFF;
    outline: none;
    transition: all .1s linear;

   }
   button.send {
    background: $phone-answer-color;
   }

   button:disabled {
    background: $color-background-alternate;
    color: $color-background-body-layout;
    cursor: not-allowed;
    span{
     color: $color-background-body-layout;
    }
   }
   button:hover {
    background: #ccc;
   }
   button.send:hover {
    background: darkgreen;
   }
   button[disabled]:hover {
    background: $color-background-alternate;
   }
  }

 }
 .icon-missed-new {
  background-image: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' stroke='%23c3b905' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cline x1='23' x2='17' y1='1' y2='7'/%3E%3Cline x1='17' x2='23' y1='1' y2='7'/%3E%3Cpath d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z'/%3E%3C/g%3E%3C/svg%3E");
 }
 .icon-missed {
  background-image: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' stroke='%23e7eee6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cline x1='23' x2='17' y1='1' y2='7'/%3E%3Cline x1='17' x2='23' y1='1' y2='7'/%3E%3Cpath d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z'/%3E%3C/g%3E%3C/svg%3E");
 }
 .icon-incoming {
  background-image: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' stroke='%23e7eee6' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='16 2 16 8 22 8'/%3E%3Cline x1='23' x2='16' y1='1' y2='8'/%3E%3Cpath d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z'/%3E%3C/g%3E%3C/svg%3E");
 }
 .icon-outgoing {
  background-image: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' stroke='%23e7eee6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpolyline points='23 7 23 1 17 1'/%3E%3Cline x1='16' x2='23' y1='8' y2='1'/%3E%3Cpath d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z'/%3E%3C/g%3E%3C/svg%3E");
 }
 .icon-canceled {
  background-image: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' stroke='%23e7eee6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7 2 2 0 0 1 1.72 2v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91'/%3E%3Cline x1='23' x2='1' y1='1' y2='23'/%3E%3C/g%3E%3C/svg%3E");
 }
}