@import "normalize.css";
@import "fontawesome.min.css";
@import "~leaflet/dist/leaflet.css";
@import "~react-datepicker/dist/react-datepicker.css";
//@import "leaflet.css";
@import "common";

html, body, #root {
 width: 100%;
 height: 100%;
 font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
 background: #504748;
 padding: 0;
 margin: 0;
}
@import "login";
@import "loaders";
@import "main";
@import "messenger";
@import "vc";
@import "oc";

@import "messages";
@import "chat";

@import "phone";
@import "map";
@import "admin";

@import "callshistory";
@import "obsvc";
@import "scheduler";
@import "online";
@import "weather";
@import "chatbot";
@import "currency";
@import "files";
@import "monitor";
@import "react-table";