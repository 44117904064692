.Admin {
 nav {
  height: 50px;
  text-align: center;

  ul {
   padding: 0;
   margin: $body-margin 0 0;
   list-style: none;
   display: inline-block;
   border: 1px $color-bg solid;
   border-radius: 5px;
   box-sizing: border-box;
   overflow: hidden;
  }
  li {
   display: inline-block;
   border-left: 1px $color-bg solid;
  }
  ul > li:first-child {
   border-left: 0;
  }
  a {
   display: inline-block;
   line-height: 50-$body-margin;
   font-size: 16px;
   background: $color-background-alternate;
   padding: 0 $body-margin;
   text-decoration: none;
   color: #fff;
  }
  a:hover {
   color: $color-text-light-alt;
  }
  a.active {
   background: $color-logo-bg;
  }
 }

 .my-icon {
  color: #D00;
 }

 .error-box {
  background: $color-header-light;
  color: #D00;
  padding: $body-margin;
  border: 1px #d00 solid;
  font-size: 80%;
 }
}

.AdminBody {
 padding: $body-margin;

 h1 {
  padding: 0;
  margin: 0;
  font-size: 26px;
  line-height: 32px;
  font-weight: normal;
  color: $color-header-light;
 }
 h2 {
  padding: 4px 0 0;
  margin: 0;
  font-size: 22px;
  line-height: 28px;
  font-weight: normal;
  color: $color-header-light;
 }

 .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  input[type=search] {
   font-size: 16px;
   line-height: 24px;
   height: 26px;
   border: 1px $color-background-body-layout solid;
   padding: 0 8px;
  }
  input[type=search]::placeholder {
  }
  .header-with-action {
   h1 {
    display: inline-block;
   }
   button {
    border: 0;
    background: transparent;
    opacity: 0.25;
    color: $color-header-light;
    border-bottom: 1px $color-header-light dashed;
    padding: 0;
    margin-left: $body-margin;
    transition: all .25s linear;
    cursor: pointer;
    outline: none;
   }
   button:hover {
    color: $color-logo-bg;
    border-bottom: 1px $color-logo-bg dashed;
   }
  }
  .header-with-action:hover {
   button {
    opacity: 1;
   }
  }
  ul.toggler {
   padding: 0;
   margin: 0 $body-margin;
   list-style: none;
   border: 1px $color-bg solid;
   border-radius: 5px;
   box-sizing: border-box;
   overflow: hidden;
   li {
    display: inline-block;
    border-left: 1px $color-bg solid;
    button {
     display: inline-block;
     line-height: 24px;
     font-size: 14px;
     padding: 0 $body-margin;
     border: 0;
     background: $color-background-alternate;
     color: #fff;
    }
    button:hover {
     color: $color-text-light-alt;
    }
    button.active {
     background: $color-logo-bg;
    }
   }
   ul > li:first-child {
    border-left: 0;
   }
  }
 }

 .list-action {
  margin: 20px 0;
  text-align: center;
  a {
   display: inline-block;
   padding: 0 $body-margin;
   margin: 0 20px;
   line-height: 32px;
   font-size: 16px;
   border-radius: 5px;
   background: $color-logo-bg;
   color: #fff;
   text-decoration: none;
   transition: all .1s linear;
  }
  a:hover {
   background: $color-background-body-layout;
   color: #eee;
  }
 }
 .table-list {
  table {
   border-collapse: collapse;
   background: $color-background-body-layout;
   width: 100%;
   th {
    text-align: left;
    padding: $body-margin;
    background: $color-background-alternate;
   }
   th.action {
    text-align: right;
   }
   td {
    padding: $body-margin;
    border-top: 1px $color-bg solid;
    transition: all .1s linear;
    a {
     color: #000;
     display: inline-block;
     text-decoration: underline;
     //border-bottom: 1px #000 dashed;
     transition: all .2s linear;
    }
    a:hover {
     color: $color-logo-bg;
    }
    .empty {
     font-style: italic;
     font-size: 80%;
     color: $color-background-alternate;
    }
    i.icon {
     margin-right: 8px;
    }
   }
   tr:hover {
    td {
     background: darken($color-background-body-layout, 2%);
    }
   }
   td.action {
    text-align: right;
    label.date {
     font-size: 80%;
    }
    button {
     @extend %label-hover-box;
     border: 0;
     background: transparent;
     transition: all .1s linear;
     cursor: pointer;
     outline: none;
     label {
      right: 100%;
      top: 50%;
      margin-top: -13px;
      white-space: nowrap;
     }
    }
    button.delete-button {
     color: $color-header-light;
    }
    button.delete-button:hover {
     color: #D00;
    }
   }
   tr.clickable {
    td {
     cursor: pointer;
    }
   }
   tr.clickable:hover {
    td {
     //color: $color-background-alternate;
     color: #fff;
     background: $color-logo-bg;
     .empty {
      color: $color-background-body-layout;
     }
    }
   }
  }
 }
 .tree-list {
  background: $color-background-body-layout;
  padding: $body-margin 0;
  ul.tree-level {
   list-style: none;
   padding: 0 0 0 25px;
   margin: 0;
   //border-bottom: 1px $color-background-alternate solid;
   li {
    font-size: 16px;
    border-top: 1px $color-background-alternate solid;
    line-height: 32px;
    transition: all .2s linear;
    a {
     color: #000;
     text-decoration: underline;
     transition: all .2s linear;
    }
    a:hover {
     color: $color-logo-bg;
    }
    .tree-head {
     display: flex;
     justify-content: space-between;
     align-items: center;
     font-size: 18px;
     .tree-sub {
      margin: 0 $body-margin;
      a {
       opacity: .1;
       font-size: 80%;
       padding-left: $body-margin;
       transition: all .25s linear;
      }
     }
    }
    .tree-head:hover {
     background: #ffffff05;
     .tree-sub {
      a {
       opacity: .6;
      }
      a:hover {
       opacity: 1;
      }
     }
    }
    .tree-head + ul {
     border-top: 1px $color-background-alternate solid;
    }
   }
   li:first-child {
    border-top: 0;
   }
  }
 }
 .list-empty {
  background: $color-background-body-layout;
  padding: $body-margin;
  //text-align: center;
  font-style: italic;
  color: $color-bg;
 }
 .form-box {
  background: $color-background-body-layout;
  position: relative;
  form {
  }
  .error-title-row {
   padding: $body-margin;
   background: $color-header-light;
   border: 1px #D00 solid;
   color: #D00;
  }
  .input-row {
   display: flex;
   align-items: center;
   padding: 20px $body-margin;
   border-bottom: 1px $color-bg solid;
   label {
    flex: 1;
   }
   input, textarea, select {
    box-sizing: border-box;
    width: 260px;
    height: 34px;
    border: $color-bg 1px solid;
    font-size: 16px;
   }
   textarea {
    padding: 4px 8px;
    line-height: 24px;
   }
   input {
    line-height: 32px;
    padding: 0 8px;
   }
   select {
    border-radius: 0;
    line-height: 32px;
    padding: 0 8px;
   }
   select.alert {
    background: #fee;
   }
  }
  .checkboxes-row {
   display: flex;
   padding: 20px $body-margin;
   border-bottom: 1px $color-bg solid;
   label {
    flex: 1;
    span.badge {
     display: none;
    }
   }
   ul.boxes {
    width: 260px;
    margin: -5px 0 0;
    padding: 0;
    list-style: none;
    li {
     padding: 5px 0;
     label {
      display: flex;
      align-items: center;
     }
     input[type=checkbox] {
      margin-right: 8px;
     }
    }
   }
  }
  .buttons-row {
   background: $color-background-alternate;
   padding: $body-margin 0;
   display: flex;
   justify-content: space-between;
   align-items: center;
   button {
    margin: 0 $body-margin;
    font-size: 16px;
    line-height: 36px;
    padding: 0 $body-margin;
    border: 0;
    border-radius: 5px;
    background: $color-bg;
    color: #fff;
    cursor: pointer;
    //box-shadow: 0 0 5px #00000066;
    transition: all .1s linear;
   }
   button:hover {
    color: $color-text-light-alt;
    box-shadow: 0 0 3px #00000066;
   }
   button[type=submit] {
    background: $color-logo-bg;
    color: #FFF;
   }
   button:disabled {
    background: $color-background-body-layout;
    color: $color-background-alternate;
    cursor: not-allowed;
   }
  }
  .error-input {
   input, textarea {
    background: pink;
   }
  }
  .row-msg {
   padding: 20px $body-margin 0;
   color: $color-bg;
   font-size: 80%;
  }
 }
 .suggestions-box {
  padding: $body-margin;
  background: $color-background-alternate;
  border-top: 1px $color-bg solid;
  input {
   box-sizing: border-box;
   font-size: 16px;
   line-height: 24px;
   height: 26px;
   border: 1px $color-background-body-layout solid;
   padding: 0 8px;
   transition: all .1s linear;
  }
  input:hover {
   background: #fff;
   border: 1px $color-background-body-layout solid;
   border-radius: 0;
  }
  input:focus {
   width: 100%;
   background: #fff;
   border: 1px $color-background-body-layout solid;
   border-radius: 0;
  }
  .react-autosuggest__container {
   position: relative;
  }
  .react-autosuggest__suggestions-container {
   background: $color-background-body-layout;
   max-height: 320px;
   overflow: auto;
   box-shadow: 0 0 10px #00000099;
   position: absolute;
   z-index: 120;
   width: 100%;
   ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
     padding: 5px $body-margin;
    }
   }
  }
  .react-autosuggest__suggestion--highlighted {
   background: $color-background-alternate;
   color: $color-header-light;
  }
 }

 .ChatBox {
  text-align: center;
  padding-top: $body-margin;
  h2 {
   font-size: 16px;
   font-weight: 100;
  }
  button {
   margin: 0 $body-margin;
   font-size: 16px;
   line-height: 36px;
   padding: 0 $body-margin;
   border: 0;
   border-radius: 5px;
   background: $color-bg;
   color: #fff;
   cursor: pointer;
   //box-shadow: 0 0 5px #00000066;
   transition: all .1s linear;
  }
  button:hover {
   background: $color-logo-bg;
   color: #FFF;
   box-shadow: 0 0 3px #00000066;
  }
  button:disabled {
   background: $color-background-body-layout;
   color: $color-background-alternate;
   cursor: not-allowed;
  }
 }

}
.AdminBody.form-wrapper {
 .form-main {
  width: 460px;
  margin-bottom: 20px;
 }
 .form-main-fluid {
  margin-bottom: 20px;
 }
}
@media screen and (min-width: 1050px) {
 .AdminBody.form-wrapper {
  display: flex;
  .form-main {
   margin-right: $body-margin*2;
   //background: lime;
  }
  .form-main-fluid {
   margin-right: $body-margin*2;
   min-width: 460px;
   max-width: 1000px;
   width: 50%;
  }
  .form-sidebar {
   flex: 1;
   min-width: 400px;
   max-width: 1000px;
   //background: pink;
  }
 }
}

.AdmFormHandler {
 position: absolute;
 left: 0;
 top: 0;
 right: 0;
 bottom: 0;
 background: #00000088;
 color: #FFFFFF;
 font-size: 36px;
 display: flex;
 align-items: center;
 justify-content: space-around;
 cursor: wait;
 i {
  animation: spinner 2s linear infinite;
  //animation: ro;
 }
}

.AdminDeleteBox {
 width: 460px;
 margin: $body-margin 0;
 text-align: center;
 button {
  font-size: 14px;
  line-height: 24px;
  border: 0;
  background: transparent;
  color: #F00;
  padding: 2px 16px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  transition: all .1s linear;
 }
 button:hover {
  background: #D00;
  color: #fff;
 }
}

.AdmMsg {
 padding: 20px;
 text-align: center;
 margin: 0;
 font-size: 26px;
 font-weight: normal;
 color: $color-header-light;
}

.AdminNotifies {
 position: fixed;
 right: 0;
 top: $header-height;
 ul {
  list-style: none;
  padding: 0;
  margin: 5px;
  li {
   padding: $body-margin;
   margin: 0 0 $body-margin;
   background: #f6f0e4e7;
   box-shadow: 0 0 5px #000000cc;
   border-radius: 5px;
   cursor: pointer;
   transition: all .1s linear;
   span {
    display: block;
    font-size: 80%;
    margin-bottom: 5px;
   }
  }
  li:hover {
   background: #f6f0e4;
  }
 }
}

.AdmUserSuggestions {
 .user-suggestion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .post {
   margin-left: $body-margin;
   font-size: 90%;
  }
 }
}

.AdmParticipants {
 h2 {
  background: $color-background-alternate;
  color: #000;
  line-height: 48px;
  margin: 0;
  padding: 0 $body-margin;
 }
 .users-list {
  list-style: none;
  background: $color-background-body-layout;
  margin: 0;
  padding: 0;
  > li {
   border-top: 1px $color-bg solid;
   padding: $body-margin;
   font-size: 16px;
   line-height: 32px;
  }
  h5 {
   margin: 0 $body-margin 0 0;
   display: inline-block;
   font-size: 18px;
   line-height: inherit;
  }
  ul.parts-list {
   margin: 0;
   padding: 0;
   list-style: none;
   display: inline;
   line-height: inherit;
   li {
    margin-right: $body-margin;
    display: inline;
    line-height: inherit;
    span {
     display: inline-block;
     background: $color-background-alternate;
     color: #000;
     border-radius: 11px;
     padding: 0 7px;
     font-size: 14px;
     line-height: 22px;
    }
    span.adm {
     background: $color-bg;
     color: $color-header-light;
    }
   }
  }
 }
}

.AdmStruct {
 .AdmStructCompanies {
  //margin-left: 0 !important;
 }
}
