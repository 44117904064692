$color-logo-bg: #c3b905;
$color-bg: #504748;
$color-background-body-layout: #857b7a;
$color-background-alternate: #766a6a;
$color-header-text: #735964;
$color-text-light: #e7eee6;
$color-text-light-alt: #eeefea;
$color-text-light-gray: #bdbbb9;
$color-header-light: #f6f0e4;

$color-online: green;
$color-online-txt: #FFF;
$color-offline: #999;
$color-offline-txt: #333;

$color-cat-accident: #A00;
$color-cat-deffect: #ff8e40;
$color-cat-lag: #aaa;

$header-height: 62px;
$sidebar-width: 76px;
$body-margin: 15px;


%h1-at-air {
 font-size: 24px;
 line-height: 30px;
 font-weight: normal;
 color: $color-text-light-alt;
 padding: 0;
 margin: 0 0 15px;
}

%label-hover-box {
 position: relative;
 label {
  position: absolute;
  z-index: 111;
  max-width: 150px;
  background: #000000dd;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  padding: 4px 8px;
  border-radius: 4px;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  transition: opacity .1s;
 }
}
%label-hover-box:hover {
 label {
  visibility: visible;
  opacity: 1;
 }
}

@keyframes spinner {
 to {transform: rotate(360deg);}
}