.OcPage {
 padding: $body-margin;

 h1 {
  padding: 0;
  margin: 0;
  font-size: 26px;
  line-height: 32px;
  font-weight: normal;
  color: $color-header-light;
 }

 .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  input[type=search] {
   font-size: 16px;
   line-height: 24px;
   height: 26px;
   border: 1px $color-background-body-layout solid;
   padding: 0 8px;
  }
  input[type=search]::placeholder {
  }
  .with-submenu {
   position: relative;
   h1 {
    display: inline;
    margin-right: 8px;
   }
   .submenu-trigger {
    background: none;
    border: 0;
    color: $color-header-light;
    outline: none;
    cursor: pointer;
    transition: all 150ms ease;
   }
   .submenu {
    position: absolute;
    z-index: 10;
    //min-width: 40vw;
    right: 0;
    top: 100%;
    margin-top: 4px;
    background: rgba($color-header-light, .9);
    box-shadow: 0 0 10px rgba(0, 0, 0, .8);
    visibility: hidden;
    opacity: 0;
    transition: opacity .1s;
    ul {
     list-style: none;
     margin: 0;
     padding: $body-margin;
     li {
      font-size: 18px;
      line-height: 24px;
      padding: 4px 0;
      margin: 0;
      a {
       color: $color-header-text;
       text-decoration: none;
       transition: all 100ms ease;
      }
      a:hover {
       color: #000;
      }
     }
    }
   }
  }
  .with-submenu.active {
   .submenu-trigger {
    transform: rotate(180deg);
   }
   .submenu {
    visibility: visible;
    opacity: 1;
   }
  }
 }
 .oc-list {
  table {
   border-collapse: collapse;
   background: $color-background-body-layout;
   width: 100%;
   th {
    text-align: left;
    padding: $body-margin;
    background: $color-background-alternate;
   }
   th.action {
    text-align: right;
   }
   td {
    position: relative;
    padding: $body-margin;
    border-top: 1px $color-bg solid;
    transition: all .1s linear;
    .empty {
     font-style: italic;
     font-size: 80%;
     color: $color-background-alternate;
    }
    a {
     color: #000;
     display: inline-block;
     text-decoration: underline;
     //border-bottom: 1px #000 dashed;
     transition: all .2s linear;
    }
    a:hover {
     color: $color-logo-bg;
     //border-bottom: 1px $color-logo-bg solid;
    }
    a.labeled-link {
     @extend %label-hover-box;
     font-size: 24px;
     label {
      right: 0;
      bottom: 100%;
      margin-bottom: 5px;
     }
    }
    button.labeled-button {
     @extend %label-hover-box;
     font-size: 24px;
     background: none;
     color: #000;
     border: 0;
     outline: none;
     cursor: pointer;
     transition: all .2s linear;
     label {
      right: 0;
      bottom: 100%;
      margin-bottom: 5px;
     }
    }
    button.labeled-button:hover {
     color: $color-logo-bg;
    }
    button.labeled-button[disabled] {
     cursor: not-allowed;
     color: $color-background-alternate;
     label {
      display: none;
     }
    }
   }
   td.actions {
    text-align: right;
    a {
     margin: 0 8px;
    }
   }
   tr:hover {
    td {
     background: darken($color-background-body-layout, 2%);
    }
   }
   td.action {
    text-align: right;
    label.date {
     font-size: 80%;
    }
    button {
     @extend %label-hover-box;
     border: 0;
     background: transparent;
     transition: all .1s linear;
     cursor: pointer;
     outline: none;
     label {
      right: 100%;
      top: 50%;
      margin-top: -13px;
      white-space: nowrap;
     }
    }
    button.delete-button {
     color: $color-header-light;
    }
    button.delete-button:hover {
     color: #D00;
    }
   }
   tr.clickable {
    td {
     cursor: pointer;
    }
   }
   tr.clickable:hover {
    td {
     //color: $color-background-alternate;
     color: #fff;
     background: $color-logo-bg;
     .empty {
      color: $color-background-body-layout;
     }
    }
   }
  }
 }
 h2.empty-list {
  margin: 0;
  font-weight: 100;
  font-size: 16px;
  text-align: center;
  color: $color-background-alternate;
 }
 .bottom-actions {
  margin: 2*$body-margin 0 $body-margin;
  text-align: center;
  button {
   background: none;
   border: none;
   cursor: pointer;
   color: $color-background-body-layout;
   font-size: 12px;
   outline: none;
   transition: all 100ms ease;
  }
  button:hover {
   color: $color-header-light;
  }
 }
}