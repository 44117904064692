$messenger-header-height: 50px;

.Messenger {
 margin-left: 25vw;
 min-width: 600px;
}

.MessengerIntro {
 position: fixed;
 left: 25vw;
 top: $header-height + $body-margin;
 right: $body-margin;
 bottom: $body-margin;
 margin-left: $sidebar-width + $body-margin*2;
 background: $color-background-body-layout;
 display: flex;
 align-items: center;
 .intro {
  color: $color-bg;
  font-size: 18px;
  font-style: italic;
  text-align: center;
  width: 100%;
  label {
   cursor: pointer;
   border-bottom: 1px $color-bg dashed;
   transition: all .1s linear;
  }
  label:hover {
   color: $color-header-light;
   border-bottom: 1px transparent solid;
  }
  .reload {
   position: fixed;
   bottom: $body-margin;
   left: 25vw;
   right: $body-margin;
   margin-left: $sidebar-width + $body-margin*2;
   margin-bottom: 10px;
   button {
    font-size: 12px;
    background: none;
    border: 0;
    cursor: pointer;
    transition: all 150ms ease;
   }
   button:hover {
    color: $color-header-light;
   }
  }
 }
}

.MessengerSidebar {
 background: $color-background-body-layout;
 position: fixed;
 left: $body-margin + $sidebar-width;
 top: $body-margin + $header-height;
 bottom: $body-margin;
 width: 25vw;

 .header {
  box-sizing: border-box;
  height: $messenger-header-height;
  position: relative;
  background: $color-background-alternate;
  //padding: 0 10px;
  border-bottom: 1px $color-bg solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ul.tabs {
   list-style: none;
   padding: 0;
   margin: 0;
   height: 100%;
   flex: 1;
   font-size: 14px;
   li {
    display: inline-block;
    box-sizing: border-box;
    width: 50%;
    height: 100%;
    border-right: 1px $color-bg solid;
    button {
     width: 100%;
     height: 100%;
     box-sizing: border-box;
     outline: none;
     border: none;
     border-radius: 0;
     position: relative;
     text-align: center;
     background-color: transparent;
     //background-color: $color-background-alternate;
     background-position: 50% 4px;
     background-repeat: no-repeat;
     padding: 28px 10px 6px;
     font-size: 14px;
     line-height: 16px;
     color: $color-text-light;
     cursor: pointer;
     transition: all .1s linear;
    }
    button:hover {
     background-color: $color-background-body-layout;
    }
   }
   li:first-child {
    //border-left: 0;
   }
   li.active {
    button {
     background-color: $color-background-body-layout;
    }
   }
   button.contacts {
    background-image: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23e7eee6'%3E%3Cpath d='M19,3H8C6.346,3,5,4.346,5,6v1H4C3.447,7,3,7.448,3,8s0.447,1,1,1h1v2H4c-0.553,0-1,0.448-1,1s0.447,1,1,1h1v2H4 c-0.553,0-1,0.448-1,1s0.447,1,1,1h1v1c0,1.654,1.346,3,3,3h11c1.654,0,3-1.346,3-3V6C22,4.346,20.654,3,19,3z M7,6 c0-0.551,0.449-1,1-1v2H7V6z M7,9h1v2H7V9z M7,13h1v2H7V13z M7,18v-1h1v2C7.449,19,7,18.551,7,18z M20,18c0,0.551-0.449,1-1,1H9V5 h10c0.551,0,1,0.449,1,1V18z'/%3E%3Ccircle cx='14' cy='10.5' r='2'/%3E%3Cpath d='M14,13.356c-1.562,0-2.5,0.715-2.5,1.429c0,0.357,0.938,0.715,2.5,0.715c1.466,0,2.5-0.357,2.5-0.715 C16.5,14.071,15.52,13.356,14,13.356z'/%3E%3C/g%3E%3C/svg%3E");
   }
   button.history {
    background-image: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='-2 -2 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23e7eee6' d='M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 L10,0 Z M10,18 C5.6,18 2,14.4 2,10 C2,5.6 5.6,2 10,2 C14.4,2 18,5.6 18,10 C18,14.4 14.4,18 10,18 Z M10.5,5 L9,5 L9,11 L14.2,14.2 L15,12.9 L10.5,10.2 L10.5,5 Z'/%3E%3C/svg%3E");
   }
  }
  .add {
   width: $messenger-header-height;
   height: 100%;
   button {
    border: 0;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    line-height: 30px;
    font-size: 24px;
    box-sizing: border-box;;
    position: relative;
    background: transparent;
    transition: all .1s linear;
    color: $color-text-light;
    cursor: pointer;
    outline: none;
    label {
     position: absolute;
     z-index: 103;
     top: 100%;
     right: 0;
     margin-top: 2px;
     margin-right: -12px;
     padding: 3px 10px;
     background: #000000CC;
     color: #FFF;
     font-size: 14px;
     border-radius: 4px;
     display: block;
     white-space: nowrap;
     visibility: hidden;
     opacity: 0;
     transition: opacity .1s linear;
    }
   }
   button:hover {
    background-color: $color-background-body-layout;
    //color: $color-logo-bg;
    label {
     opacity: 1;
     visibility: visible;
    }
   }
   button:disabled {
    color: $color-background-body-layout;
    cursor: not-allowed;
    label {
     opacity: 0 !important;
     visibility: hidden;
    }
   }
  }
  h2 {
   font-weight: normal;
   font-size: 18px;
   line-height: $messenger-header-height - 1px;
   color: $color-header-light;
   padding: 0;
   margin: 0;
  }
 }
 .list {
  position: fixed;
  left: inherit;
  width: inherit;
  bottom: inherit;
  top: $body-margin + $header-height + $messenger-header-height;
  overflow: auto;
  ul {
   list-style: none;
   padding: 0;
   margin: 0;
   li {
    padding: 0;
    margin: 0;
    position: relative;
    .badge {
     color: yellowgreen;
     position: absolute;
     right: 5px;
     top: 5px;
    }
    span.unread {
     position: absolute;
     right: 5px;
     top: 50%;
     margin-top: -9px;
     background: $color-logo-bg;
     color: #FFF;
     display: inline-block;
     font-size: 12px;
     line-height: 18px;
     padding: 0 8px;
     border-radius: 9px;
    }
    a {
     position: relative;
     transition: all 100ms linear;
     display: block;
     border-bottom: 1px solid #979797;
     border-left: 9px transparent solid;
     padding: 8px 0 8px 10px;
     //padding: 5px 0 5px 64px;
     color: $color-text-light-alt;
     text-decoration: none;
     font-size: 16px;
     line-height: 24px;
     .chat-box {
      display: flex;
      align-items: center;
      cursor: inherit;
      i.ch-type-ico {
       margin-right: 8px;
       font-size: 20px;
       width: 32px;
       text-align: center;
      }
      i.silent-ico {
       font-size: 80%;
       margin-right: .4em;
      }
      label {
       flex: 1;
       cursor: inherit;
      }
     }
     .chat-undefined {
      font-size: 90%;
      opacity: .75;
     }
     .chat-undefined:before {
      content: "{";
     }
     .chat-undefined:after {
      content: "}";
     }
    }
    a.cat-accident {
     i.ch-type-ico {
      color: $color-cat-accident !important;
     }
    }
    a.cat-deffect {
     i.ch-type-ico {
      color: $color-cat-deffect !important;
     }
    }
    a.cat-lag {
     i.ch-type-ico {
      color: $color-cat-lag !important;
     }
    }
    a:hover {
    }
    a.active, a:hover {
     border-left: 9px solid $color-logo-bg;
     background-color: #766a6a;
    }
    .chat-user {
     display: block;
    }
    .chat-user-post {
     font-size: 12px;
     font-style: italic;
    }
   }
  }
  .no-chats {
   padding: 10px;
   font-style: italic;
   color: $color-bg;
  }
 }
}

.MessengerBody {
 margin-left: 2*$body-margin;
 margin-top: $body-margin + $messenger-header-height;
 margin-right: $body-margin;
 .header {
  position: fixed;
  z-index: 10;
  left: 25vw;
  right: $body-margin;
  top: $header-height;
  height: $messenger-header-height + $body-margin;
  margin-left: $sidebar-width + $body-margin*2;
  border-top: $body-margin $color-bg solid;
  box-sizing: border-box;
  background: $color-background-alternate;
  border-bottom: 1px $color-bg solid;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //white-space: nowrap;
  h2 {
   font-weight: normal;
   font-size: 18px;
   line-height: $messenger-header-height - 1px;
   color: $color-header-light;
   padding: 0;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   margin: 0;
   .chat-name-helper {
    font-size: 60%;
    margin-left: 1em;
   }
  }
  .controls {
   white-space: nowrap;
  }
  .helper-row {
   position: absolute;
   top: 2px;
   font-size: 12px;
   line-height: 16px;
   justify-content: space-between;
   .label-left {
    color: $color-header-light;
   }
   .label-right {
    background: $color-background-alternate;
    margin-left: 1.5em;
    padding: 0 6px;
    border-radius: 8px;
   }
   .cat-accident {
    background: $color-cat-accident !important;
    color: #FFF;
   }
   .cat-deffect {
    background: $color-cat-deffect !important;
   }
   .cat-lag {
    background: $color-cat-lag !important;
   }
  }
  .helper-row + h2 {
   padding-top: 8px;
   line-height: $messenger-header-height - 9px;
  }

  button {
   position: relative;
   font-size: 18px;
   line-height: 36px;
   text-align: center;
   width: 36px;
   height: 36px;
   box-sizing: border-box;
   padding: 0;
   margin-left: 15px;
   border: none;
   border-radius: 18px;
   background: $color-header-light;
   color: $color-header-text;
   transition: all .1s linear;
   cursor: pointer;
   outline: none;
   label {
    position: absolute;
    z-index: 150;
    top: 100%;
    right: 0;
    margin-top: 3px;
    max-width: 180px;
    background: #000000dd;
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    padding: 4px 8px;
    border-radius: 4px;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    transition: opacity .1s;
   }
  }
  button:hover {
   background: darken($color-header-light, 10);
   color: darken($color-header-text, 10);
   label {
    visibility: visible;
    opacity: 1;
   }
  }
  button:disabled {
   color: $color-background-body-layout;
   cursor: not-allowed;
   label {
    opacity: 0 !important;
    visibility: hidden;
   }
  }
 }
 .body {
  background: $color-background-body-layout;
  ul.tabs {
   list-style: none;
   padding: 0;
   margin: 0;
   display: flex;
   background: $color-background-alternate;
   li {
    border-left: 1px $color-bg solid;
    width: 33.33%;
    button {
     background: none;
     width: 100%;
     border: 0;
     border-bottom: 1px $color-bg solid;
     outline: none;
     text-align: center;
     font-size: 14px;
     line-height: $messenger-header-height;
     color: $color-text-light;
     cursor: pointer;
     transition: all .1s linear;
     background-color: transparent;
    }
    button:hover {
     background-color: $color-background-body-layout;
    }
    button[disabled] {
     color: $color-bg;
     cursor: not-allowed;
     font-style: italic;
    }
    button[disabled]:hover {
     background-color: transparent;
    }
   }
   li:first-child {
    border-left: 0;
   }
   li.active {
    button {
     border-bottom: 1px $color-background-body-layout solid;
     background-color: $color-background-body-layout;
    }
    button:hover {}
    button[disabled] {
     background-color: $color-background-body-layout;
    }
   }
  }
 }
 .messages {
 }
 .form {
 }
}

.ChatMsgForm {
 position: fixed;
 z-index: 98;
 left: 25vw;
 right: $body-margin;
 margin-left: $sidebar-width + $body-margin*2;
 bottom: 0;
 border-bottom: $body-margin $color-bg solid;
 .files-input-box {
  position: fixed;
  left: -10000px;
 }
 form {
  box-sizing: border-box;
  padding: 15px 80px 15px 60px;
  position: relative;
  min-height: 42px;
  border-top: 1px $color-bg solid;
  background: $color-background-alternate;
 }
 ul.mentioned {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
   display: inline-block;
   padding: 0;
   margin: 0 8px 8px 0;
   label {
    display: inline-block;
    background: #d0cbc8;
    font-size: 14px;
    line-height: 18px;
    padding: 0 4px;
    border-radius: 4px;
    cursor: pointer;
    transition: all .1s linear;
    i {
     margin-left: 4px;
     font-size: 75%;
     opacity: .7;
    }
   }
   label:hover {
    background: $color-header-light;
   }
  }
 }
 textarea {
  min-height: 18px;
  line-height: 18px;
  width: 100%;
  resize: vertical;
  background: white;
  //box-sizing: border-box;
  padding: 5px 10px;
  border: 0;
 }
 textarea:disabled {
  background: #eee;
  color: #aaa;
  cursor: wait;
 }
 textarea::placeholder {
  color: #ddd;
 }
 button {
  display: inline-block;
  position: absolute;
  //top: 0;
  //bottom: 0;
  bottom: 12px;
  appearance: none;
  background: none;
  border: 0;
  width: 60px;
  min-height: 42px;
  text-align: center;
  vertical-align: center;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  color: $color-header-light;
 }
 button:disabled {
  color: $color-background-body-layout;
  cursor: not-allowed;
 }
 button.button-send-msg {
  right: 0;
 }
 button.button-send-files {
  left: 0;
 }
}

.ChatMsgs {
 background: $color-background-body-layout;
 padding-bottom: 20px;
 ul {
  list-style: none;
  padding: 5px 20px;
  margin: 0 0 50px;
  li {
  }
 }
 .no-messages {
  padding: 25px 0 10px;
  text-align: center;
  color: $color-bg;
  font-style: italic;
 }
}

.ChatMsgForm + .ChatMsgs {
 margin-bottom: 100px;
}

.ChatMsg {
 padding: 5px 0;
 .date {
  text-align: center;
  margin: 10px 0;
  width: 100%;
  line-height: 20px;
  position: relative;

  label {
   font-size: 14px;
   color: #d0cbc8;
   display: inline-block;
   padding: 0 15px;
   z-index: 10;
  }
  label:before, label:after {
   content: "";
   display: block;
   position: absolute;
   top: 10px;
   border-top: 1px #d0cbc8aa solid;
  }
  label:before {
   left: 0;
   right: 50%;
   margin-right: 50px;
  }
  label:after {
   left: 50%;
   right: 0;
   margin-left: 50px;
  }
 }
 .msg {
  display: inline-block;
  border-radius: 5px;
  padding: 10px;
  .user {
   font-size: 90%;
   font-weight: bold;
   color: $color-bg;
   padding-bottom: 5px;
   .OnlineDot {

   }
  }
  ul.mentions {
   list-style: none;
   padding: 0;
   margin: 0;
   li {
    display: inline-block;
    font-size: 80%;
    line-height: 1.6em;
    margin: 0 0 .2em;
    //padding: 0 .6em;
    //border-radius: 4px;
    //background: $color-logo-bg;
    //color: #FFF;
   }
   li.itsme {
    color: #D00;
   }
  }
  .files {
   list-style: none;
   padding: 0;
   margin: 0;
   li {
    line-height: 1.4em;
    a {
     text-decoration: none;
     color: #000;
     transition: color .1s linear;
     i {
      margin-right: .6em;
     }
    }
    a:hover {
     color: $color-background-alternate;
    }
    span.size {
     color: #444;
     font-size: 70%;
     margin-left: 12px;
    }
   }
  }
  .time {
   color: #444;
   font-size: 70%;
   //margin-left: 20px;
  }
 }
}

.ChatMsg.from_me {
 text-align: right;
 .msg {
  border-top-right-radius: 0;
  background: #d0cbc8;
  ul.mentions {
   li {
    margin-left: 8px;
   }
  }
 }
}

.ChatMsg.to_me {
 .msg {
  border-top-left-radius: 0;
  background: $color-header-light;
  ul.mentions {
   li {
    margin-right: 8px;
   }
  }
 }
}

.ChatMsg.unread {
 .msg {
  background: yellowgreen;
  cursor: pointer;
 }
}

.ChatFormError {
 padding: 40px 20px;
 text-align: center;
 color: #C00;
}

.ChatForm {
 padding: 10px 20px;
 color: $color-bg;
 h3 {
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: normal;
 }
 .name-row {
  display: flex;
  align-items: center;
  padding: 10px 0;
  h3 {
   margin-right: 20px;
   min-width: 100px;
  }
  input {
   font-size: 16px;
   flex: 1;
   border: 1px $color-bg solid;
   padding: 4px 10px;
  }
  select {
   //flex: 1;
   border: 1px $color-bg solid;
   border-radius: 0;
   font-size: 16px;
  }
 }
 .users-row {
  padding: 10px 0;
  .title {
   display: flex;
   align-items: center;
   justify-content: space-between;
   h3 {
   }
   input {
    border: 1px $color-bg solid;
    font-size: 13px;
    padding: 2px 8px;
    border-radius: 4px;
   }
  }
  ul {
   background: $color-background-body-layout;
   color: #111;
   list-style: none;
   padding: 0;
   margin: 15px 0 0;
   max-height: 40px*8;
   overflow: auto;
   border-top: 1px $color-background-alternate solid;
   li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px;
    min-height: 31px;
    border-bottom: 1px $color-background-alternate solid;
    label {
     input[type=checkbox] {
      margin-right: 4px;
     }
    }
    .role-selector {

    }
    a.chat-link {
     color: #111;
     font-size: 14px;
     line-height: 20px;
     text-decoration: none;
     transition: all .1s linear;
    }
    a.chat-link:hover {
     color: $color-logo-bg;
    }
    button.chat-button {
     font-size: 14px;
     line-height: 20px;
     background: $color-bg;
     color: $color-header-light;
     padding: 0 15px;
     border-radius: 3px;
     border: 0;
     cursor: pointer;
     transition: all .1s linear;
    }
    button.chat-button:hover {
     color: #FFF;
    }
   }
  }
  .no-users {
   margin-top: 15px;
   font-style: italic;
  }
 }
 .actions-row {
  padding: 10px 0;
  button {
   margin: 0 10px;
   font-size: 16px;
   line-height: 30px;
   background: $color-bg;
   color: $color-header-light;
   padding: 0 15px;
   border-radius: 3px;
   border: 0;
   cursor: pointer;
   transition: all .1s linear;
  }
  button:hover {
   color: #FFF;
  }
  button:disabled {
   background: $color-background-alternate;
   color: $color-background-body-layout;
   cursor: not-allowed;
  }
 }
}