.OnlineStatus {
 font-size: 11px;
 line-height: 16px;
 padding: 0 5px;
 border-radius: 4px;
 margin-left: 8px;
 display: inline-block;
}
.OnlineStatus.online {
 background: $color-online;
 color: $color-online-txt;
}
.OnlineStatus.offline {
 background: $color-offline;
 color: $color-offline-txt;
}
.OnlineDot {
 display: inline-block;
 //border: #333 1px solid;
 box-sizing: border-box;
 width: 10px;
 height: 10px;
 border-radius: 50%;
 background: $color-online;
 margin-left: 8px;
}