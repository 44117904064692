.AppLoader {
 display: flex;
 height: 100%;
 //background: pink;
 align-items: center;
 justify-content: center;

 label {
  font-style: italic;
  font-size: 80%;
  color: #857d7b;
  display: inline-block;
  min-width: 50px;

 }
}

.PageLoader {
 //display: flex;
 //align-items: center;
 //justify-content: center;
 //background: yellowgreen;
 height: inherit;
 padding: 10px;
 text-align: center;
 label {
  font-style: italic;
  font-size: 80%;
  color: #857d7b;
  display: inline-block;
  min-width: 50px;
 }
}

//@keyframes loader {
// 100% {
//  transform: rotate(360deg);
// }
//}


.PageError {
 margin: $body-margin;
 padding: $body-margin;
 background: #D00;
 color: #fafafa;
 display: flex;
 align-items: center;
 i {
  margin-right: 1em;
  font-size: 24px;
 }
 label {
  font-size: 18px;
 }
}